import React, { FC, useRef, useState } from 'react';
import { useStyles } from './IntroVideoModal.styles';
import { FSModal } from '../../../../../../common/components/modal/Modal';
import { useVideoControls } from '../../../../../../common/components/VideoPlayer/PlayerControls.helper';
import { Box, Button, CardMedia, Divider, Typography } from '@mui/material';
import { CampaignSummaryIntroVideoStrings } from '../../../../../../common/localization/en';
import AddVideo from './AddVideo/AddVideo';
import RecordVideo from '../../../../../rewards/CampaignSummary/Modals/WelcomeVideoModal/RecordVideo/RecordVideo';
import { PlayIcon } from '../../../../../../common/assets/newDesign/VideoControlsIcons/PlayIcon';
import { LoadingButton } from '@mui/lab';
import {
  cancelVideoAsFileUpload,
  createStorageUploadUrl,
  reset,
  uploadVideoAsBlob,
} from '../../../../../../store/slices/uploadVideoSlice';
import { useAppDispatch } from '../../../../../../store';
import { MediaFilePrefix } from '../../../../../../api/models/common';
import { getUpdatedVideoUrl } from '../../../../../../common/components/VideoUploader/VideoUplader.helpers';
import EditThumbnail from './EditThumbnail/EditThumbnail';
import { ThumbnailFrame } from '../../../../../../common/components/EditThumbnail/EditThumbnail';
import { getVideoDuration } from '../../../../../../services/utilities';
import LargeFileModal from '../LargeFileModal/LargeFileModal';
import { useModal } from '../../../../../../hooks/useModal';
import ProgressBarWithCompletion from '../../../../../../common/components/ProgressBarWithCompletion/ProgressBarWithCompletion';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string | null | undefined;
  thumbnailUrl: string | null | undefined;
  updateVideoUrl: (newValue: Object) => Promise<void>;
}

const IntroVideoModal: FC<Props> = ({
  isOpen,
  onClose,
  videoUrl,
  thumbnailUrl,
  updateVideoUrl,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [introVideoUrl, setIntroVideoUrl] = useState(videoUrl);
  const [isRecordMode, setIsRecordMode] = useState(false);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<string | null>(null);
  const [isRecordedVideoUploading, setIsRecordedVideoUploading] = useState(false);
  const [isUploadedVideoUploading, setIsUploadedVideoUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailFrame>();
  const [isNewUpload, setIsNewUpload] = useState(false);

  const handleClose = () => {
    setPlayerState({ ...playerState, isPlaying: false });
    onClose();
  };

  const handleRecordMode = () => {
    setIsRecordMode((prevState) => !prevState);
  };

  const handleVideoUrlChange = (url: string | null) => {
    setIntroVideoUrl(url);
  };

  const handleVideoRecorded = (blob: string) => {
    setRecordedVideoBlob(blob);
    submitVideo(blob);
  };

  const handleCancel = () => {
    if (isRecordMode) {
      setIsRecordMode(false);
      setRecordedVideoBlob(null);
    } else {
      if (cancelVideoAsFileUpload) {
        cancelVideoAsFileUpload();
      }
      handleClose();
    }
    dispatch(reset());
  };

  const uploadAbortController = useRef(new AbortController());
  const onUploadProgress = (event: ProgressEvent) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgress(percent);
  };

  const submitVideo = async (recordedVideoBlob: string) => {
    if (!recordedVideoBlob) return;

    return new Promise(async (resolve, reject) => {
      try {
        uploadAbortController.current.signal.addEventListener('abort', (e) => {
          reject('upload aborted');
        });

        setProgress(0);
        setIsRecordedVideoUploading(true);

        const res = await createStorageUploadUrl({ ext: 'mp4', prefix: MediaFilePrefix.Intro });
        if (!res) {
          return reject('createStorageUploadUrl failed');
        }

        const introVideoUrl = res.downloadUrl;
        if (!introVideoUrl) {
          return reject('no downloadUrl');
        }

        await dispatch(
          uploadVideoAsBlob({
            options: res,
            data: recordedVideoBlob,
            onUploadProgress,
          }),
        );

        resolve(introVideoUrl);
      } catch (error) {
        reject(error);
      }
    })
      .then(async (introVideoUrl) => {
        if (!introVideoUrl || typeof introVideoUrl !== 'string') return;

        const url = await getUpdatedVideoUrl(introVideoUrl);
        setIntroVideoUrl(url);
        setIsRecordMode(false);
        setRecordedVideoBlob(null);
      })
      .catch((error) => {
        if (uploadAbortController.current.signal.aborted) {
          // reset abort controller
          uploadAbortController.current = new AbortController();
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setIsRecordedVideoUploading(false);
      });
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    let duration: number | null = null;
    if (introVideoUrl) {
      duration = await getVideoDuration(introVideoUrl);
    }

    await updateVideoUrl({
      videoUrl: introVideoUrl || null,
      thumbnailUrl: introVideoUrl
        ? selectedThumbnail
          ? selectedThumbnail.thumbnail.src
          : introVideoUrl.replace('mp4', 'png')
        : null,
      duration: duration ? Math.floor(duration) : null,
    });
    setIsLoading(false);
    handleClose();
  };

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const { playerState, setPlayerState, togglePlayer, handleOnTimeUpdate } = useVideoControls(
    refVideo,
    false,
  );

  const {
    isOpen: isLargeFileModalOpen,
    close: closeLargeFileModal,
    open: openLargeFileModal,
  } = useModal();

  return (
    <>
      <LargeFileModal isOpen={isLargeFileModalOpen} onClose={closeLargeFileModal} />
      <FSModal
        onClose={handleClose}
        modalIsOpen={isOpen}
        rounded="12px"
        blur
        width="980px"
        padding="0"
      >
        <Box className={classes.container}>
          <Typography className={classes.title}>
            {CampaignSummaryIntroVideoStrings.Title}
          </Typography>
          <Typography className={classes.text}>{CampaignSummaryIntroVideoStrings.Text}</Typography>
          <Divider className={classes.divider} />
          <Box className={classes.contentWrapper}>
            <Box className={classes.leftSideWrapper}>
              <Box>
                <Typography className={classes.methodText}>
                  {CampaignSummaryIntroVideoStrings.ChooseMethod}
                </Typography>
                {isRecordedVideoUploading && (
                  <Box className={classes.progressWrapper}>
                    <ProgressBarWithCompletion
                      progress={progress}
                      className={classes.progress}
                      text={CampaignSummaryIntroVideoStrings.CompletedUploadText}
                    />
                  </Box>
                )}
                <AddVideo
                  onIntroVideoUrlChange={(url: string | null) => {
                    handleVideoUrlChange(url);
                    setIsNewUpload(true);
                  }}
                  onRecordClick={handleRecordMode}
                  isRecordMode={isRecordMode}
                  videoUrl={introVideoUrl}
                  isUploading={isUploadedVideoUploading}
                  setIsUploading={setIsUploadedVideoUploading}
                  openLargeFileModal={openLargeFileModal}
                />
                {introVideoUrl && (
                  <EditThumbnail
                    videoUrl={introVideoUrl}
                    selectedThumbnail={selectedThumbnail}
                    onThumbnailChange={setSelectedThumbnail}
                  />
                )}
              </Box>
              <Box className={classes.buttonsWrapper}>
                <Button
                  className={`${classes.button} ${classes.cancelButton}`}
                  onClick={handleCancel}
                >
                  {CampaignSummaryIntroVideoStrings.Cancel}
                </Button>
                <LoadingButton
                  className={`${classes.button} ${classes.confirmButton}`}
                  disabled={isRecordMode || isUploadedVideoUploading}
                  onClick={handleConfirm}
                  loading={isLoading}
                >
                  {CampaignSummaryIntroVideoStrings.Confirm}
                </LoadingButton>
              </Box>
            </Box>
            <Box className={classes.rightSideWrapper}>
              {isRecordMode ? (
                <RecordVideo
                  onVideoRecorded={handleVideoRecorded}
                  recordedVideo={recordedVideoBlob}
                />
              ) : (
                <Box className={classes.videoWrapper}>
                  {!playerState.isPlaying && introVideoUrl && (
                    <Box className={classes.playIconWrapper} onClick={togglePlayer}>
                      <PlayIcon />
                    </Box>
                  )}
                  {introVideoUrl && (
                    <CardMedia
                      component="video"
                      ref={refVideo}
                      poster={
                        selectedThumbnail?.thumbnail.src ||
                        (!isNewUpload && thumbnailUrl) ||
                        introVideoUrl.replace('mp4', 'png')
                      }
                      src={introVideoUrl}
                      onClick={togglePlayer}
                      onTimeUpdate={handleOnTimeUpdate}
                      onEnded={() => {
                        setPlayerState({ ...playerState, isPlaying: false });
                      }}
                      preload="auto"
                    />
                  )}
                </Box>
              )}
              <Typography className={classes.videoText}>
                {isRecordMode
                  ? CampaignSummaryIntroVideoStrings.RecordIntroVideo
                  : introVideoUrl
                  ? CampaignSummaryIntroVideoStrings.PreviewIntroVideo
                  : CampaignSummaryIntroVideoStrings.NoIntroSet}
              </Typography>
            </Box>
          </Box>
        </Box>
      </FSModal>
    </>
  );
};

export default IntroVideoModal;

import { FC, useEffect, useRef } from 'react';
import { useStyles } from './Elements.styles';
import { Box, Tabs, Tab } from '@mui/material';
import { ElementsStrings } from '../../../../../common/localization/en';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import IconButton from '@mui/material/IconButton';
import { NavPrevIcon } from '../../../../../common/assets/NavPrevIcon';
import { NavNextIcon } from '../../../../../common/assets/NavNextIcon';
import clsx from 'clsx';
import { InviteCustomersTab } from './InviteCustomersTab/InviteCustomersTab';
import { InvitationMessageTab } from './InvitationMessageTab/InvitationMessageTab';
import { WelcomeVideoTab } from './WelcomeVideoTab/WelcomeVideoTab';
import { RewardMessageTab } from './RewardMessageTab/RewardMessageTab';
import { VideoSharingTab } from './VideoSharingTab/VideoSharingTab';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../common/constants/events/rewardCampaignsEvents';
import { useParams } from 'react-router-dom';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { SendInvitesTab } from './SendInvitesTab/SendInvitesTab';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { TabDirectionIcon } from '../../../../../common/assets/TabDirectionIcon';
import {
  getLastSendBatchStats,
  getUnsentInvitations,
} from '../../../../../store/slices/campaignsSlice';
import { useQuickStartLaunchPad } from '../../../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { setElementsActiveTabIndex } from '../../../../../store/slices/uiSlice';

interface TabItem {
  title: string;
  content: JSX.Element;
  eventName: RewardCampaignsEvents;
}

export const Elements: FC = () => {
  const { currentTopicStep, isStarted: isQsFlow } = useQuickStartLaunchPad();

  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();
  const { elementsActiveTabIndex: activeTabIndex } = useTypedSelector((state) => state.ui);

  useEffect(() => {
    slideTo(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  const dispatch = useAppDispatch();

  const sliderRef = useRef<SwiperRef | null>(null);
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  const {
    campaignUnsentInvitations: { totalItems: unsentInvitesCount, page, size, sort, search },
    lastSendBatchStats,
    isLastSendBatchStatsLoading,
    currentCampaign,
  } = useTypedSelector((state) => state.campaigns);

  useEffect(() => {
    dispatch(getUnsentInvitations({ campaignId, pageable: {} }));
    dispatch(getLastSendBatchStats({ campaignId }));
  }, [campaignId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setElementsActiveTabIndex(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultTabs: TabItem[] = [
    {
      title: isHealthCare ? ElementsStrings.AddPatients : ElementsStrings.AddCustomers,
      content: <InviteCustomersTab />,
      eventName: RewardCampaignsEvents.InviteCustomersTabOpen,
    },
    {
      title: ElementsStrings.InvitationMessage,
      content: <InvitationMessageTab />,
      eventName: RewardCampaignsEvents.InvitationMessageTabOpen,
    },
    {
      title: ElementsStrings.WelcomeVideo,
      content: <WelcomeVideoTab />,
      eventName: RewardCampaignsEvents.WelcomeVideoTabOpen,
    },
    {
      title: currentCampaign?.isNullReward
        ? ElementsStrings.ThankYouTextMessage
        : ElementsStrings.RewardTextMessage,
      content: <RewardMessageTab />,
      eventName: RewardCampaignsEvents.RewardMessageTabOpen,
    },
    {
      title: ElementsStrings.VideoSharing,
      content: <VideoSharingTab />,
      eventName: RewardCampaignsEvents.VideoSharingTabOpen,
    },
  ];

  const tabs = currentCampaign?.endedAt ? defaultTabs.slice(1) : defaultTabs;

  if (unsentInvitesCount || lastSendBatchStats?.sentAt || isLastSendBatchStatsLoading) {
    // show send-invites tab only when user has unsent invites
    tabs.push({
      title: ElementsStrings.SendInvites,
      content: <SendInvitesTab />,
      eventName: RewardCampaignsEvents.SendInvitesTabOpen,
    });
  }

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
      dispatch(setElementsActiveTabIndex(activeTabIndex - 1));
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideNext();
      dispatch(setElementsActiveTabIndex(activeTabIndex + 1));
    }
  };

  const slideTo = (index: number) => {
    if (sliderRef.current) {
      sliderRef.current.swiper.slideTo(index);
      dispatch(setElementsActiveTabIndex(index));
    }
  };

  useEffect(() => {
    if (!isQsFlow) return;
    const activeIndex = currentTopicStep === 0 ? 0 : currentTopicStep - 1;
    slideTo(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTopicStep, dispatch, isQsFlow]);

  useEffect(() => {
    dispatch(
      getUnsentInvitations({
        campaignId: campaignId,
        search,
        pageable: { page, size, sort },
      }),
    );
  }, [campaignId, dispatch, page, search, size, sort]);

  return (
    <Box className={classes.container}>
      <Tabs
        className={classes.tabs}
        onChange={(_, value) => {
          slideTo(value);
          trackEvent(tabs[value].eventName, {
            campaignId,
          });
        }}
        value={activeTabIndex}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
      >
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={tab.title}
              label={tab.title}
              className={classes.tab}
              sx={{
                marginRight: index < tabs.length - 1 ? '20px' : '0',
                '&:hover': {
                  border: '1px solid #D9D9D9',
                },
              }}
              icon={
                <Box
                  className={classes.directionIcon}
                  sx={{
                    display: index < tabs.length - 1 ? 'block' : 'none',
                  }}
                >
                  <TabDirectionIcon />
                </Box>
              }
            />
          );
        })}
      </Tabs>
      <Box className={classes.swiperWrapper}>
        <Swiper
          slidesPerView="auto"
          className={classes.swiper}
          spaceBetween={0}
          ref={sliderRef}
          speed={400}
          allowTouchMove={false}
        >
          {tabs.map((tab) => (
            <SwiperSlide key={tab.title} className={classes.swiperSlide}>
              {tab.content}
            </SwiperSlide>
          ))}
        </Swiper>
        <IconButton
          onClick={handlePrev}
          className={clsx(classes.navIcon, classes.navPrevIcon)}
          disabled={activeTabIndex === 0}
          style={{
            opacity: activeTabIndex === 0 ? '0.3' : '1',
          }}
        >
          <NavPrevIcon />
        </IconButton>
        <IconButton
          onClick={handleNext}
          className={clsx(classes.navIcon, classes.navNextIcon)}
          disabled={activeTabIndex === tabs.length - 1}
          style={{
            opacity: activeTabIndex === tabs.length - 1 ? '0.3' : '1',
          }}
        >
          <NavNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

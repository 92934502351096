import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { TableDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { CampaignsEndpoints, getApiUrlForId } from '../../api/endpoints';
import { GetTableDataResponse, GetTableDataWithSearchRequest } from '../../api/models/common';
import {
  CampaignsApiModel,
  CampaignUser,
  CampaignVideosApiModel,
  CreateCampaignInvitationOptions,
  CampaignInvitation,
  CreateIncentiveCampaignApiModel,
  EditUsersInCampaignOptions,
  ETSCalculationOptions,
  FundraisingCalculationOptions,
  GetCampaignsAPIModel,
  GetCampaignsRequest,
  GetCampaignUsersRequest,
  GetCampaignUsersRequestOptions,
  GetCampaignVideosApiModel,
  GetCampaignVideosRequest,
  GetCampaignVideosRequestOptions,
  GetExistedHeroesCampaignApiModel,
  GetRewardCardRequestOptions,
  MarketingCalculationOptions,
  PatchIncentiveCampaignApiModel,
  PatchIncentiveCampaignOptions,
  RewardCard,
  SalesCalculationOptions,
  SendCampaignInvitationsOptions,
  UserIncentiveCampaignsApiModel,
  GetUnsentInvitationsOptions,
  GetUnsentInvitationsResponse,
  GetActiveInvitationsOptions,
  GetActiveInvitationsResponse,
  CampaignInvitationWithUserDetails,
  CreateCampaignInvitationBatchOptions,
  GetUserInvitedCampaignsResponse,
  UserInvitedCampaignsApiModel,
  InviteCountsModel,
  LastSendBatchStatsModel,
  ReSendCampaignInvitationOptions,
} from '../../api/models/campaigns';
import { RewardsCalculationApiModel } from '../../api/models/rewards';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import axios from 'axios';
import {
  CampaignInvitationBatchesResponse,
  GetTransactionAPIModel,
  TransactionRecordRequest,
} from '../../api/models/transaction';
import { getUnbrandedThumbnail } from '../../services/utilities';
import { DEFAULT_UWM_CAMPAIGN_NAME } from '../../common/constants/defaults';

export interface CampaignCountData {
  users: number;
  activeUsers: number;
  fulfilledUsers: number;
  activeInvitations: number;
  unsentInvitations: number;
  queuedInvitations: number;
  pendingUsers: number;
}

type CvrBySourceKey = 'SMS' | 'URL' | 'QR_CODE';

export interface CampaignStats {
  visited: number;
  creators: number;
  total: number;
  cvr: number;
  cvrBySource: {
    [key in CvrBySourceKey]: {
      videos: number;
      users: number;
      cvr: number;
    };
  };
}

interface InitialState extends TableDTO<CampaignsApiModel> {
  activeCampaign: CampaignsApiModel | null;
  isCreationCampaignModal: boolean;
  isCalculationResultLoading: boolean;
  campaignCreationInProcessing: boolean;
  calculationResult: RewardsCalculationApiModel | null;
  recommendedValue: number[];
  title: string;
  subtitle: string;
  rewardTitle: string;
  rewardDescription: string;
  couponEnabled: boolean;
  rewardCard?: string;
  isRewardCardLoading?: boolean;
  isNullReward: boolean;
  currentCampaign: CampaignsApiModel | null;
  currentCampaignUsers: TableDTO<CampaignUser>;
  currUserActiveCampaign: CampaignsApiModel | null;
  isActiveCampaignLoading: boolean;
  countData: CampaignCountData;
  isCountDataLoading: boolean;
  campaignVideos: CampaignVideosApiModel[];
  userIncentiveCampaigns: UserIncentiveCampaignsApiModel[];
  invitationBatches: TableDTO<GetTransactionAPIModel>;
  primaryCampaign: CampaignsApiModel | null;
  campaignSummaryUserDetails: {
    isOpen: boolean;
    userId: string;
    videoId: string;
    fromRecentVideos: boolean;
  };
  campaignSummaryVideoDetails: {
    isOpen: boolean;
    userId: string;
    videoId: string;
  };
  campaignUnsentInvitations: TableDTO<CampaignInvitation>;
  campaignQueuedInvitations: TableDTO<CampaignInvitation>;
  campaignActiveInvitations: {
    invitations: CampaignInvitationWithUserDetails[];
    pendingUsers: CampaignUser[];
    isLoading: boolean;
    isLoaded: boolean;
    error: boolean;
    search: string;
  };
  userInvitedCampaigns: UserInvitedCampaignsApiModel[];
  activeUserInvitedCampaginsCount: number;
  latestAddedCampaignUserId?: string;
  creatorsTableActiveTabIndex: number;
  tablesActiveTabIndex: number;
  isLoaded: boolean;
  uwmRedirectCampaign: CampaignsApiModel | null;
  allItemsChecked?: boolean;
  openAddCustomerModalTrigger: boolean;
  inviteCounts: InviteCountsModel;
  invitations: CampaignInvitationWithUserDetails[];
  campaignStats: CampaignStats | null;
  isInvitesLoading: boolean;
  lastSendBatchStats: LastSendBatchStatsModel | null;
  isLastSendBatchStatsLoading: boolean;
  isCampaignLoading: boolean;
  isDefaultCampaignCreating: boolean;
}

const emptyTableState = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
};

const campaignUsersInitState: TableDTO<CampaignUser> = emptyTableState;

const campaignUnsentInvitationsInitState: TableDTO<CampaignInvitation> = emptyTableState;

const campaignQueuedInvitationsInitState: TableDTO<CampaignInvitation> = emptyTableState;

const invitationBatchesInitialState: TableDTO<GetTransactionAPIModel> = emptyTableState;

const initialState: InitialState = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  activeCampaign: null,
  isCreationCampaignModal: false,
  calculationResult: null,
  recommendedValue: [],
  isCalculationResultLoading: false,
  campaignCreationInProcessing: false,
  title: '',
  subtitle: '',
  rewardTitle: '',
  rewardDescription: '',
  rewardCard: '',
  couponEnabled: true,
  isNullReward: false,
  currentCampaign: null,
  currentCampaignUsers: campaignUsersInitState,
  currUserActiveCampaign: null,
  isActiveCampaignLoading: false,
  countData: {
    users: 0,
    activeUsers: 0,
    fulfilledUsers: 0,
    activeInvitations: 0,
    unsentInvitations: 0,
  } as CampaignCountData,
  isCountDataLoading: false,
  campaignVideos: [],
  userIncentiveCampaigns: [],
  invitationBatches: invitationBatchesInitialState,
  primaryCampaign: null,
  campaignSummaryUserDetails: {
    isOpen: false,
    userId: '',
    videoId: '',
    fromRecentVideos: false,
  },
  campaignSummaryVideoDetails: {
    isOpen: false,
    userId: '',
    videoId: '',
  },
  campaignUnsentInvitations: campaignUnsentInvitationsInitState,
  campaignQueuedInvitations: campaignQueuedInvitationsInitState,
  campaignActiveInvitations: {
    invitations: [],
    pendingUsers: [],
    isLoading: false,
    isLoaded: false,
    error: false,
    search: '',
  },
  userInvitedCampaigns: [],
  activeUserInvitedCampaginsCount: 0,
  creatorsTableActiveTabIndex: 0,
  tablesActiveTabIndex: 3,
  isLoaded: false,
  uwmRedirectCampaign: null,
  allItemsChecked: false,
  campaignStats: null,
  openAddCustomerModalTrigger: false,
  inviteCounts: {
    creators: 0,
    total: 0,
    delivered: 0,
    undelivered: 0,
    visited: 0,
  },
  invitations: [],
  isInvitesLoading: false,
  lastSendBatchStats: {
    campaignId: '',
    sentAt: '',
    stats: {
      invitesSent: 0,
      invitesDelivered: 0,
      optOuts: 0,
      visitors: 0,
      videos: 0,
    },
  },
  isLastSendBatchStatsLoading: false,
  isCampaignLoading: false,
  isDefaultCampaignCreating: false,
};

export const getCampaignVideos = createAsyncThunk(
  'campaigns/getCampaignVideos',
  async (options: GetCampaignVideosRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetCampaignVideosRequest, GetCampaignVideosApiModel>({
        url: CampaignsEndpoints.GetCampaignVideos.replace('{id}', options.id),
        requiresToken: true,
        params: {
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getCampaignById = createAsyncThunk(
  'campaigns/getRewardCampaignById',
  async (id: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetRewardCampaignByIdWithStats, id),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getCampaignUsersAPICall = (options: GetCampaignUsersRequestOptions) => {
  return httpClient.get<GetCampaignUsersRequest, GetExistedHeroesCampaignApiModel>({
    url: CampaignsEndpoints.GetCampaignUsers.replace('{id}', options.id),
    requiresToken: true,
    params: {
      page: options.pageable.page?.toString(),
      size: options.pageable.size?.toString() || '10000',
      sort: options.pageable.sort?.toString(),
      search: options.search || undefined,
      status: options.status,
      fulfilled: options.filter?.fulfilled?.toString(),
      hasVideos: options.hasVideos,
    },
  });
};

export const getCampaignUsers = createAsyncThunk(
  'campaigns/getCampaignUsers',
  async (options: GetCampaignUsersRequestOptions, { rejectWithValue }) => {
    try {
      return getCampaignUsersAPICall(options);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getCampaigns = createAsyncThunk(
  'campaigns/getCampaigns',
  async (options: GetTableDataWithSearchRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetCampaignsRequest, GetTableDataResponse<GetCampaignsAPIModel>>({
        url: CampaignsEndpoints.GetIncentiveCampaigns,
        requiresToken: true,
        params: {
          accountId: options.accountId,
          page: options.pageable.page?.toString(),
          size: options.pageable.size?.toString(),
          sort: options.pageable.sort?.toString(),
          search: options.search,
        },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveIncentiveCampaign = createAsyncThunk(
  'campaigns/getActiveIncentiveCampaign',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetActiveIncentiveCampaign, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUserActiveCampaign = createAsyncThunk(
  'campaigns/getUserActiveCampaign',
  async (options: { userId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetUserActiveCampaign, options.userId),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postSalesCalculation = createAsyncThunk(
  'campaigns/postSalesCalculation',
  async (options: SalesCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfSalesReps: number;
            incentiveBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostSalesCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postFundraisingCalculation = createAsyncThunk(
  'campaigns/postFundraisingCalculation',
  async (options: FundraisingCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfHeroes: number;
            rewardBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostFundraisingCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postETSCalculation = createAsyncThunk(
  'campaigns/postETSCalculation',
  async (options: ETSCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            numberOfHeroes: number;
            rewardBudget: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostETSCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postMarketingCalculation = createAsyncThunk(
  'campaigns/postMarketingCalculation',
  async (options: MarketingCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            monthlyDigitalMarketingBudget: number;
            averageCartCheckout: number;
          },
        RewardsCalculationApiModel
      >({
        url: CampaignsEndpoints.PostMarketingCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postEndIncentiveCampaign = createAsyncThunk(
  'campaigns/postEndIncentiveCampaign',
  async (options: string, { rejectWithValue }) => {
    try {
      return await httpClient.post<undefined, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.PostEndIncentiveCampaign, options),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const postIncentiveCampaign = createAsyncThunk(
  'campaigns/postIncentiveCampaign',
  async (options: CreateIncentiveCampaignApiModel) => {
    try {
      return await httpClient.post<CreateIncentiveCampaignApiModel, CampaignsApiModel>({
        url: CampaignsEndpoints.PostIncentiveCampaigns,
        requiresToken: true,
        payload: options,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const patchIncentiveCampaign = createAsyncThunk(
  'campaigns/patchIncentiveCampaign',
  async (options: PatchIncentiveCampaignOptions) => {
    try {
      await httpClient.put<PatchIncentiveCampaignApiModel, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.PatchIncentiveCampaign, options.campaignId),
        requiresToken: true,
        payload: options.values,
      });

      return await httpClient.get<undefined, CampaignsApiModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetRewardCampaignByIdWithStats, options.campaignId),
        requiresToken: true,
      });
    } catch (err) {
      throw new Error();
    }
  },
);

export const getRewardCardPreview = createAsyncThunk(
  'campaigns/getRewardCardPreview',
  async (options: GetRewardCardRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<GetRewardCardRequestOptions, Blob>({
        url: CampaignsEndpoints.GenerateRewardCard,
        requiresToken: true,
        payload: options,
        isBlob: true,
      });
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRewardCardUrl = createAsyncThunk(
  'campaigns/getRewardCardUrl',
  async (options: GetRewardCardRequestOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<GetRewardCardRequestOptions, RewardCard>({
        url: CampaignsEndpoints.GenerateRewardCardUrl,
        requiresToken: true,
        payload: options,
      });
    } catch (error) {
      console.log('error', error);
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const addUsersToCampaign = createAsyncThunk(
  'campaigns/addUsersToCampaign',
  async (options: EditUsersInCampaignOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<EditUsersInCampaignOptions['payload'], { success: boolean }>({
        url: getApiUrlForId(CampaignsEndpoints.AddUsersToCampaign, options.campaignId),
        requiresToken: true,
        payload: options.payload,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const removeUsersFromCampaign = createAsyncThunk(
  'campaigns/removeUsersFromCampaign',
  async (options: EditUsersInCampaignOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<EditUsersInCampaignOptions['payload'], { success: boolean }>({
        url: getApiUrlForId(CampaignsEndpoints.RemoveUsersFromCampaign, options.campaignId),
        requiresToken: true,
        payload: options.payload,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignCountData = createAsyncThunk(
  'campaigns/getCampaignCountData',
  async ({ campaignId }: { campaignId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, CampaignCountData>({
        url: getApiUrlForId(CampaignsEndpoints.GetCampaignCountData, campaignId),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getUserIncentiveCampaigns = createAsyncThunk(
  'campaigns/getUserIncentiveCampaigns',
  async (
    options: { userId: string; ongoing?: boolean; withCampaignDetails?: boolean },
    { rejectWithValue },
  ) => {
    try {
      let userIncentiveCampaigns = await httpClient.get<
        { ongoning: boolean },
        UserIncentiveCampaignsApiModel[]
      >({
        url: getApiUrlForId(CampaignsEndpoints.GetUserIncentiveCampaigns, options.userId),
        params: {
          ongoning: options.ongoing || false,
        },
        requiresToken: true,
      });

      if (options.withCampaignDetails) {
        const campaignDetails = [];
        for await (const { campaignId } of userIncentiveCampaigns) {
          campaignDetails.push(
            httpClient.get<undefined, CampaignsApiModel>({
              url: getApiUrlForId(CampaignsEndpoints.GetRewardCampaignByIdWithStats, campaignId),
              requiresToken: true,
            }),
          );
        }

        await Promise.all(campaignDetails).then(
          (campaigns) =>
            (userIncentiveCampaigns = userIncentiveCampaigns.map((currentCampaign, index) => ({
              ...currentCampaign,
              campaign: campaigns[index],
            }))),
        );
      }

      return { userIncentiveCampaigns };
    } catch (error) {
      // @ts-ignore
      rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignInvitationBatches = createAsyncThunk(
  'campaigns/getCampaignInvitationBatches',
  async (
    options: { id: string; page: number; size: number; sort: string[] },
    { rejectWithValue },
  ) => {
    try {
      return await httpClient.get<TransactionRecordRequest, CampaignInvitationBatchesResponse>({
        url: getApiUrlForId(CampaignsEndpoints.GetCampaignInvitationBatches, options.id),
        requiresToken: true,
        params: {
          page: options.page,
          size: options.size,
          sort: options.sort?.toString(),
        },
      });
    } catch (error) {
      // @ts-ignore
      rejectWithValue(error.response.data.message);
    }
  },
);

export const getPrimaryCampaign = createAsyncThunk(
  'campaigns/getPrimaryCampaign',
  async (_options: { venueId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, GetCampaignsAPIModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetPrimaryCampaign, _options.venueId),
        requiresToken: false,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createInvitation = createAsyncThunk(
  'campaigns/createInvitation',
  async (options: CreateCampaignInvitationOptions, { rejectWithValue, dispatch }) => {
    try {
      return httpClient
        .post<Omit<CreateCampaignInvitationOptions, 'campaignId'>, CampaignInvitation>({
          url: getApiUrlForId(CampaignsEndpoints.CreateInvitation, options.campaignId),
          payload: {
            phoneNumber: options.phoneNumber,
            firstName: options.firstName,
            lastName: options.lastName,
            transactionDate: options.transactionDate,
          },
          requiresToken: true,
        })
        .then((data) => {
          dispatch(getCampaignCountData({ campaignId: options.campaignId }));
          return data;
        })
        .catch((error) => {
          return rejectWithValue(error.response.data);
        });
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const createInvitationBatch = createAsyncThunk(
  'campaigns/createInvitationBatch',
  async (options: CreateCampaignInvitationBatchOptions, { rejectWithValue, dispatch }) => {
    try {
      return httpClient.post<
        Omit<CreateCampaignInvitationBatchOptions, 'campaignId'>,
        CampaignInvitation[]
      >({
        url: getApiUrlForId(CampaignsEndpoints.SendInvitationsBatch, options.campaignId),
        payload: {
          invitations: options.invitations,
          groupName: options.groupName,
          sendImmediately: options.sendImmediately,
          ignoreTimeWindow: true,
        },
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const sendInvitations = createAsyncThunk(
  'campaigns/sendInvitations',
  async (options: SendCampaignInvitationsOptions, { rejectWithValue, dispatch }) => {
    try {
      return httpClient
        .post<Omit<SendCampaignInvitationsOptions, 'campaignId'>, { success: true }>({
          url: getApiUrlForId(CampaignsEndpoints.SendInvitations, options.campaignId),
          payload: {
            invitationIds: options.invitationIds,
          },
          requiresToken: true,
        })
        .then((data) => {
          dispatch(getCampaignCountData({ campaignId: options.campaignId }));
          return data;
        });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const resendInvitation = createAsyncThunk(
  'campaigns/resendInvitation',
  async (options: ReSendCampaignInvitationOptions, { rejectWithValue, dispatch }) => {
    try {
      return httpClient
        .post<Omit<ReSendCampaignInvitationOptions, 'campaignId'>, { success: true }>({
          url: getApiUrlForId(
            CampaignsEndpoints.ReSendCampaignInvitations,
            options.campaignId,
          ).replace(':invitationId', options.invitationId),
          requiresToken: true,
        })
        .then((data) => {
          dispatch(getCampaignCountData({ campaignId: options.campaignId }));
          return data;
        });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteInvitations = createAsyncThunk(
  'campaigns/deleteInvitations',
  async (options: { campaignId: string }, { rejectWithValue, dispatch }) => {
    try {
      return httpClient.post<{ campaignId: string }, { success: true }>({
        url: getApiUrlForId(CampaignsEndpoints.DeleteInvitations, options.campaignId),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getUnsentInvitationsAPICall = (options: GetUnsentInvitationsOptions) => {
  return httpClient.get<
    { page?: string; size?: string; sort?: string; search?: string },
    GetUnsentInvitationsResponse
  >({
    url: getApiUrlForId(CampaignsEndpoints.GetUnsentInvitations, options.campaignId),
    params: {
      page: options.pageable.page?.toString(),
      size: options.pageable.size?.toString(),
      sort: options.pageable.sort?.toString(),
      search: options.search,
    },
    requiresToken: true,
  });
};

export const getUnsentInvitations = createAsyncThunk(
  'campaigns/getUnsentInvitations',
  async (options: GetUnsentInvitationsOptions, { rejectWithValue }) => {
    try {
      return getUnsentInvitationsAPICall(options);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getActiveInvitationsAPICall = (options: GetActiveInvitationsOptions) => {
  return httpClient.get<{}, GetActiveInvitationsResponse>({
    url: getApiUrlForId(CampaignsEndpoints.GetActiveInvitations, options.campaignId),
    requiresToken: true,
  });
};

export const getActiveInvitations = createAsyncThunk(
  'campaigns/getActiveInvitations',
  async (options: GetActiveInvitationsOptions, { rejectWithValue }) => {
    try {
      return getActiveInvitationsAPICall(options);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getCampaignInvitations = createAsyncThunk(
  'campaigns/getCampaignInvitations',
  async (options: GetActiveInvitationsOptions, { rejectWithValue }) => {
    try {
      const deliveredInvites = (
        await httpClient.get<{}, { items: CampaignInvitationWithUserDetails[] }>({
          url: getApiUrlForId(CampaignsEndpoints.GetDeliveredInvitations, options.campaignId),
          requiresToken: true,
          params: {
            size: 100000,
          },
        })
      ).items;

      const undeliveredInvites = (
        await httpClient.get<{}, { items: CampaignInvitationWithUserDetails[] }>({
          url: getApiUrlForId(CampaignsEndpoints.GetUndeliveredInvitations, options.campaignId),
          requiresToken: true,
          params: {
            size: 100000,
          },
        })
      ).items;

      return deliveredInvites.concat(undeliveredInvites);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface SelfInviteParams {
  campaignId: string;
  followUpAfter?: number;
}

export const postInvitationSelfInviteAPICall = ({
  campaignId,
  followUpAfter,
}: SelfInviteParams) => {
  return httpClient.post<{ followUpAfter?: number }, CampaignsApiModel>({
    url: getApiUrlForId(CampaignsEndpoints.PostSelfInvite, campaignId),
    requiresToken: true,
    params: {
      followUpAfter,
    },
  });
};

export const postInvitationSelfInvite = createAsyncThunk(
  'campaigns/postInvitationSelfInvite',
  async (options: { campaignId: string }, { rejectWithValue }) => {
    try {
      return postInvitationSelfInviteAPICall(options);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getVisitors = createAsyncThunk(
  'campaigns/getVisitors',
  async (_options: { id: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, CampaignStats>({
        url: getApiUrlForId(CampaignsEndpoints.Visitors, _options.id),
        requiresToken: true,
      });
    } catch (error) {
      // @ts-ignore
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetQueuedInvitationsOptions {
  campaignId: string;
  search?: string;
  pageable: {
    page?: number;
    size?: number;
    sort?: string[];
  };
}

export interface GetQueuedInvitationsResponse {
  items: CampaignInvitation[];
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export const getQueuedInvitationsAPICall = (options: GetQueuedInvitationsOptions) => {
  return httpClient.get<
    { page?: string; size?: string; sort?: string; search?: string },
    GetUnsentInvitationsResponse
  >({
    url: getApiUrlForId(CampaignsEndpoints.GetQueuedInvitations, options.campaignId),
    params: {
      page: options.pageable.page?.toString(),
      size: options.pageable.size?.toString(),
      sort: options.pageable.sort?.toString(),
      search: options.search,
    },
    requiresToken: true,
  });
};

export const getQueuedInvitations = createAsyncThunk(
  'campaigns/getQueuedInvitations',
  async (options: GetQueuedInvitationsOptions, { rejectWithValue }) => {
    try {
      return getQueuedInvitationsAPICall(options);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetUserInvitedCampaignsOptions {
  accountId: string;
  phoneNumber: string;
  pageable: {
    page: number;
    size: number;
    sort: string[];
  };
}

export const getUserInvitedCampaigns = createAsyncThunk(
  'campaigns/getUserInvitedCampaigns',
  async (options: GetUserInvitedCampaignsOptions) => {
    return httpClient.get<GetUserInvitedCampaignsOptions, GetUserInvitedCampaignsResponse>({
      url: CampaignsEndpoints.GetUserInvitedCampaigns,
      params: {
        accountId: options.accountId,
        phoneNumber: options.phoneNumber,
        pageable: options.pageable,
      },
      requiresToken: true,
    });
  },
);

export const getInvitesCounts = createAsyncThunk(
  'campaigns/invitesCounts',
  async ({ campaignId }: { campaignId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, InviteCountsModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetInviteCounts, campaignId),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

export const getLastSendBatchStats = createAsyncThunk(
  'campaigns/getLastSendBatchStats',
  async ({ campaignId }: { campaignId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, LastSendBatchStatsModel>({
        url: getApiUrlForId(CampaignsEndpoints.GetLastSendBatchStats, campaignId),
        requiresToken: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  },
);

interface RemoveQueuedInvitesOptions {
  invitationIds: string[];
  campaignId: string;
}

export const removeQueuedInvites = async ({
  campaignId,
  invitationIds,
}: RemoveQueuedInvitesOptions) => {
  return httpClient.post<{}, { success: boolean }>({
    url: getApiUrlForId(CampaignsEndpoints.RemoveFromQueue, campaignId),
    requiresToken: true,
    payload: {
      invitationIds,
    },
  });
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setCampaignsSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state), action.payload);
      return {
        ...initialState,
        activeCampaign: state.activeCampaign,
        isCreationCampaignModal: state.isCreationCampaignModal,
        calculationResult: state.calculationResult,
        isCalculationResultLoading: state.isCalculationResultLoading,
        sort: sorting,
        size: state.size,
      };
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    updateRequestsSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    setIsCreationCampaignModal(state, action: PayloadAction<boolean>) {
      state.isCreationCampaignModal = action.payload;
    },
    reset: (state) => initialState,
    resetCurrentRewardCampaign(state) {
      state.currentCampaign = initialState.currentCampaign;
    },
    resetCurrentCampaignUsers(state) {
      state.currentCampaignUsers = initialState.currentCampaignUsers;
    },
    //existed users table reducers
    goToSelectedPageExistingCampaignUsersTable(state, action: PayloadAction<number>) {
      state.currentCampaignUsers.page = action.payload;
    },
    setExistingCampaignUsersTableSorting(state, action: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.currentCampaignUsers), action.payload);
      state.currentCampaignUsers.sort = sorting;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
      // return {
      //   ...initialState,
      //   sort: sorting,
      //   search: state.search,
      //   size: state.size,
      // };
    },
    updateExistingCampaignUsersTableSize(state, action: PayloadAction<number>) {
      state.currentCampaignUsers.size = action.payload;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
    },
    updateExistingCampaignUsersTableSearch(state, action: PayloadAction<string>) {
      state.currentCampaignUsers.search = action.payload;
      state.currentCampaignUsers.page = initialState.currentCampaignUsers.page;
    },
    updateCampaignUsersLoading(state, action: PayloadAction<boolean>) {
      state.currentCampaignUsers.isLoading = action.payload;
    },
    resetUserIncentiveCampaigns(state) {
      state.userIncentiveCampaigns = [];
    },
    setInvitationBatchesSorting(state, { payload }: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.invitationBatches), payload);
      state.invitationBatches.sort = sorting;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
    updateInvitationBatchesSize(state, { payload }: PayloadAction<number>) {
      state.invitationBatches.size = payload;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
    updateInvitationBatchesSearch(state, { payload }: PayloadAction<string>) {
      state.invitationBatches.search = payload;
      state.invitationBatches.page = initialState.invitationBatches.page;
    },
    openCampaignSummaryUserDetails(state, { payload }: PayloadAction<string>) {
      state.campaignSummaryUserDetails.isOpen = true;
      state.campaignSummaryUserDetails.userId = payload;
    },
    openCampaignSummaryUserDetailsRecentVideos(
      state,
      { payload }: PayloadAction<{ userId: string; videoId: string }>,
    ) {
      state.campaignSummaryUserDetails.isOpen = true;
      state.campaignSummaryUserDetails.userId = payload.userId;
      state.campaignSummaryUserDetails.videoId = payload.videoId;
      state.campaignSummaryUserDetails.fromRecentVideos = true;
    },
    closeCampaignSummaryUserDetails(state) {
      state.campaignSummaryUserDetails.isOpen = false;
      state.campaignSummaryUserDetails.userId = '';
      state.campaignSummaryUserDetails.videoId = '';
      state.campaignSummaryUserDetails.fromRecentVideos = false;
    },
    updateCampaignActiveInvitationsSearch(state, action: PayloadAction<string>) {
      state.campaignActiveInvitations.search = action.payload;
    },
    updateCampaignUnsentInvitationsSearch(state, action: PayloadAction<string>) {
      state.campaignUnsentInvitations.search = action.payload;
    },
    setCampaignUnsentInvitationsSorting(state, { payload }: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.campaignUnsentInvitations), payload);
      state.campaignUnsentInvitations.sort = sorting;
      state.campaignUnsentInvitations.page = initialState.campaignUnsentInvitations.page;
    },
    setCampaignUnsentInvitationsPagination(
      state,
      { payload }: PayloadAction<{ size?: number; page?: number }>,
    ) {
      if (payload.size !== undefined) {
        state.campaignUnsentInvitations.size = payload.size;
      }

      if (payload.page !== undefined) {
        state.campaignUnsentInvitations.page = payload.page;
      }
    },
    updateCampaignQueuedInvitationsSearch(state, action: PayloadAction<string>) {
      state.campaignQueuedInvitations.search = action.payload;
    },
    setQueuedInvitationsSorting(state, { payload }: PayloadAction<string>) {
      const sorting = getTableSorting(current(state.campaignQueuedInvitations), payload);
      state.campaignQueuedInvitations.sort = sorting;
      state.campaignQueuedInvitations.page = initialState.invitationBatches.page;
    },
    openCampaignSummaryVideoDetails(
      state,
      { payload }: PayloadAction<{ userId: string; videoId: string }>,
    ) {
      state.campaignSummaryVideoDetails.isOpen = true;
      state.campaignSummaryVideoDetails.userId = payload.userId;
      state.campaignSummaryVideoDetails.videoId = payload.videoId;
    },
    closeCampaignSummaryVideoDetails(state) {
      state.campaignSummaryVideoDetails.isOpen = false;
      state.campaignSummaryVideoDetails.userId = '';
      state.campaignSummaryVideoDetails.videoId = '';
    },
    updateInviteesCountData(state, { payload }: PayloadAction<number>) {
      state.countData.activeInvitations = payload;
    },
    updateVideoThumbnail(
      state,
      { payload }: PayloadAction<{ id: string; thumbnailUrl: string; altThumbnailUrl: string }>,
    ) {
      state.campaignVideos = state.campaignVideos.map((video) => {
        if (video.id !== payload.id) {
          return video;
        }
        return {
          ...video,
          thumbnailUrl: payload.thumbnailUrl,
          altThumbnailUrl: payload.altThumbnailUrl,
        };
      });
    },
    setActiveUserInvitedCampaignsCount(state, { payload }: PayloadAction<number>) {
      state.activeUserInvitedCampaginsCount = payload;
    },
    resetActiveUserInvitedCampaignsCount(state) {
      state.activeUserInvitedCampaginsCount = 0;
    },
    setLatestAddedCampaignUserId(state, { payload }: PayloadAction<string | undefined>) {
      state.latestAddedCampaignUserId = payload;
    },
    setCreatorsTableActiveTabIndex(state, { payload }: PayloadAction<number>) {
      state.creatorsTableActiveTabIndex = payload;
    },
    setTablesActiveTabIndex(state, { payload }: PayloadAction<number>) {
      state.tablesActiveTabIndex = payload;
    },
    setAllItemsChecked(state, { payload }: PayloadAction<boolean>) {
      state.allItemsChecked = payload;
    },
    resetVisitors(state) {
      state.campaignStats = null;
    },
    setOpenAddCustomersModalTrigger(state, { payload }: PayloadAction<boolean>) {
      state.openAddCustomerModalTrigger = payload;
    },
    setIsDefaultCampaignCreating(state, { payload }: PayloadAction<boolean>) {
      state.isDefaultCampaignCreating = payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getCampaigns.rejected, (state) => {
      tableLoadRejected(state);
    });
    reducersBuilder.addCase(getCampaigns.pending, (state) => {
      tableLoadPending(state);
    });
    reducersBuilder.addCase(getCampaigns.fulfilled, (state, { payload }) => {
      state.uwmRedirectCampaign =
        payload.items.find((item) => item.name === DEFAULT_UWM_CAMPAIGN_NAME || item.isPreferred) ||
        payload.items[0];

      tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(postIncentiveCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(postIncentiveCampaign.pending, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(postIncentiveCampaign.fulfilled, (state, { meta, payload }) => {
      state.activeCampaign = payload;
      state.items = [payload, ...state.items];
      state.title = meta.arg.title!;
      state.subtitle = meta.arg.subtitle!;
      state.isLoading = false;
      state.error = false;
    });
    reducersBuilder.addCase(patchIncentiveCampaign.pending, (state, { meta, payload }) => {
      state.isCampaignLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(patchIncentiveCampaign.rejected, (state, { meta, payload }) => {
      state.isCampaignLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(patchIncentiveCampaign.fulfilled, (state, { meta, payload }) => {
      state.isCampaignLoading = false;
      state.error = false;
      state.title = meta.arg.values.title!;
      state.subtitle = meta.arg.values.action!;

      state.currentCampaign = payload;
    });
    reducersBuilder.addCase(getActiveIncentiveCampaign.fulfilled, (state, { payload }) => {
      state.activeCampaign =
        state.activeCampaign?.id && state.activeCampaign.id === payload.id
          ? { ...state.activeCampaign, ...payload }
          : payload;
    });

    reducersBuilder.addCase(getUserActiveCampaign.pending, (state, { payload }) => {
      state.isActiveCampaignLoading = true;
    });

    reducersBuilder.addCase(getUserActiveCampaign.rejected, (state, { payload }) => {
      state.currUserActiveCampaign = null;
      state.isActiveCampaignLoading = false;
    });

    reducersBuilder.addCase(getUserActiveCampaign.fulfilled, (state, { payload }) => {
      state.currUserActiveCampaign = payload;
      state.isActiveCampaignLoading = false;
    });
    reducersBuilder.addCase(getActiveIncentiveCampaign.rejected, (state, { payload }) => {
      state.activeCampaign = null;
    });
    reducersBuilder.addCase(getCampaignById.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignById.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentCampaign = payload;
      }
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignById.rejected, (state, { payload }) => {
      state.currentCampaign = null;
      state.isLoading = false;
    });

    reducersBuilder.addCase(getCampaignCountData.pending, (state, { payload }) => {
      state.isCountDataLoading = true;
    });
    reducersBuilder.addCase(getCampaignCountData.fulfilled, (state, { payload }) => {
      if (payload) {
        state.countData = payload;
      }
      state.isCountDataLoading = false;
    });
    reducersBuilder.addCase(getCampaignCountData.rejected, (state, { payload }) => {
      state.isCountDataLoading = false;
    });

    reducersBuilder.addCase(getCampaignUsers.pending, (state, { payload }) => {
      state.currentCampaignUsers.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignUsers.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentCampaignUsers.items = payload.items;
        state.currentCampaignUsers.totalItems = payload.totalItems;
        state.currentCampaignUsers.totalPages = payload?.totalPages;
        state.currentCampaignUsers.page = payload?.page;
        state.currentCampaignUsers.size = payload?.size;
      }
      state.currentCampaignUsers.isLoading = false;
      state.currentCampaignUsers.isLoaded = true;
    });
    reducersBuilder.addCase(getCampaignUsers.rejected, (state, { payload }) => {
      state.currentCampaignUsers.isLoading = false;
      state.currentCampaignUsers.error = true;
    });

    reducersBuilder.addCase(postSalesCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postSalesCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.isCalculationResultLoading = false;
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const incentiveValuePerSalesRep = action.payload.incentiveValuePerSalesRep ?? 0;
        state.recommendedValue = [incentiveValuePerSalesRep];
      },
    );
    reducersBuilder.addCase(postFundraisingCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postFundraisingCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const rewardValue = action.payload.rewardValue ?? 0;
        state.recommendedValue = [rewardValue];
      },
    );
    reducersBuilder.addCase(postETSCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postETSCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const rewardValue = action.payload.rewardValue ?? 0;
        state.recommendedValue = [rewardValue];
      },
    );
    reducersBuilder.addCase(postMarketingCalculation.pending, (state) => {
      state.isCalculationResultLoading = true;
    });
    reducersBuilder.addCase(
      postMarketingCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.calculationResult = action.payload;
        state.campaignCreationInProcessing = true;
        const projectedHeroes = action.payload.projectedHeroes ?? 0;
        const recommendedRewardBudget = action.payload.recommendedRewardBudget ?? 0;
        state.recommendedValue = [projectedHeroes, recommendedRewardBudget];
      },
    );
    reducersBuilder.addCase(postEndIncentiveCampaign.fulfilled, (state, { payload }) => {
      state.lastUpdated = new Date().toISOString();
      state.activeCampaign = null;
      state.calculationResult = null;
      state.campaignCreationInProcessing = false;
      state.recommendedValue = [];
      state.isLoading = false;
      state.error = false;
      if (state.currentCampaign?.id === payload.id) state.currentCampaign.endedAt = payload.endedAt;
    });
    reducersBuilder.addCase(postEndIncentiveCampaign.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(postEndIncentiveCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getRewardCardPreview.pending, (state) => {
      state.isRewardCardLoading = true;
    });
    reducersBuilder.addCase(getRewardCardPreview.fulfilled, (state, action) => {
      const objectURL = URL.createObjectURL(action.payload);
      state.isRewardCardLoading = false;
      const img = new Image();
      img.src = objectURL;
      state.rewardCard = objectURL;
    });

    reducersBuilder.addCase(addUsersToCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(addUsersToCampaign.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(addUsersToCampaign.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    reducersBuilder.addCase(getCampaignVideos.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignVideos.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignVideos.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaignVideos = getUnbrandedThumbnail(action.payload?.items || [])!;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getUserIncentiveCampaigns.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.userIncentiveCampaigns = payload.userIncentiveCampaigns;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.fulfilled, (state, { payload }) => {
      if (!payload) return;

      state.invitationBatches.items = payload.items;
      state.invitationBatches.page = payload.page;
      state.invitationBatches.size = payload.size;
      state.invitationBatches.totalItems = payload.totalItems;
      state.invitationBatches.totalPages = payload.totalPages;

      state.invitationBatches.isLoading = false;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.pending, (state) => {
      state.invitationBatches.isLoading = true;
    });
    reducersBuilder.addCase(getCampaignInvitationBatches.rejected, (state) => {
      state.invitationBatches.isLoading = true;
    });
    reducersBuilder.addCase(getPrimaryCampaign.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getPrimaryCampaign.rejected, (state) => {
      state.isLoading = false;
      state.primaryCampaign = null;
    });
    reducersBuilder.addCase(getPrimaryCampaign.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.primaryCampaign = payload;
      }
    });
    reducersBuilder.addCase(getVisitors.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getVisitors.rejected, (state) => {
      state.isLoading = false;
    });
    reducersBuilder.addCase(getVisitors.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.campaignStats = payload;
      }
    });
    reducersBuilder.addCase(getUnsentInvitations.pending, (state, { payload }) => {
      state.campaignUnsentInvitations.isLoading = true;
    });
    reducersBuilder.addCase(getUnsentInvitations.fulfilled, (state, { payload }) => {
      if (payload) {
        state.campaignUnsentInvitations.items = payload.items;
        state.campaignUnsentInvitations.totalItems = payload.totalItems;
        state.campaignUnsentInvitations.totalPages = payload?.totalPages;
        state.campaignUnsentInvitations.page = payload?.page;
        // state.campaignUnsentInvitations.size = payload?.size;
      }
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignUnsentInvitations.isLoaded = true;
    });
    reducersBuilder.addCase(getUnsentInvitations.rejected, (state, { payload }) => {
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignUnsentInvitations.error = true;
    });

    reducersBuilder.addCase(getQueuedInvitations.pending, (state, { payload }) => {
      state.campaignQueuedInvitations.isLoading = true;
    });
    reducersBuilder.addCase(getQueuedInvitations.fulfilled, (state, { payload }) => {
      if (payload) {
        state.campaignQueuedInvitations.items = payload.items;
        state.campaignQueuedInvitations.totalItems = payload.totalItems;
        state.campaignQueuedInvitations.totalPages = payload?.totalPages;
        state.campaignQueuedInvitations.page = payload?.page;
        state.campaignQueuedInvitations.size = payload?.size;
      }
      state.campaignQueuedInvitations.isLoading = false;
      state.campaignQueuedInvitations.isLoaded = true;
    });
    reducersBuilder.addCase(getQueuedInvitations.rejected, (state, { payload }) => {
      state.campaignQueuedInvitations.isLoading = false;
      state.campaignQueuedInvitations.error = true;
    });

    reducersBuilder.addCase(getActiveInvitations.pending, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = true;
    });
    reducersBuilder.addCase(getActiveInvitations.fulfilled, (state, { payload }) => {
      if (payload) {
        state.campaignActiveInvitations.invitations = payload.invitations;
        state.campaignActiveInvitations.pendingUsers = payload.pendingUsers;
      }
      state.campaignActiveInvitations.isLoading = false;
      state.campaignActiveInvitations.isLoaded = true;
    });
    reducersBuilder.addCase(getActiveInvitations.rejected, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = false;
      state.campaignActiveInvitations.error = true;
    });

    reducersBuilder.addCase(sendInvitations.pending, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = true;
      state.campaignUnsentInvitations.isLoading = true;
      state.campaignActiveInvitations.error = false;
      state.campaignUnsentInvitations.error = false;
    });

    reducersBuilder.addCase(sendInvitations.fulfilled, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = false;
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignActiveInvitations.isLoaded = true;
      state.campaignUnsentInvitations.isLoaded = true;
    });

    reducersBuilder.addCase(sendInvitations.rejected, (state, { payload }) => {
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignActiveInvitations.isLoading = false;
      state.campaignActiveInvitations.error = true;
      state.campaignUnsentInvitations.error = true;
    });

    reducersBuilder.addCase(resendInvitation.pending, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = true;
      state.campaignUnsentInvitations.isLoading = true;
      state.campaignActiveInvitations.error = false;
      state.campaignUnsentInvitations.error = false;
    });

    reducersBuilder.addCase(resendInvitation.fulfilled, (state, { payload }) => {
      state.campaignActiveInvitations.isLoading = false;
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignActiveInvitations.isLoaded = true;
      state.campaignUnsentInvitations.isLoaded = true;
    });

    reducersBuilder.addCase(resendInvitation.rejected, (state, { payload }) => {
      state.campaignUnsentInvitations.isLoading = false;
      state.campaignActiveInvitations.isLoading = false;
      state.campaignActiveInvitations.error = true;
      state.campaignUnsentInvitations.error = true;
    });

    reducersBuilder.addCase(getUserInvitedCampaigns.rejected, (state) => {
      state.userInvitedCampaigns = [];
    });

    reducersBuilder.addCase(getUserInvitedCampaigns.fulfilled, (state, { payload }) => {
      state.userInvitedCampaigns = payload.content;
    });

    reducersBuilder.addCase(getInvitesCounts.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getInvitesCounts.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getInvitesCounts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.inviteCounts = payload;
      }
    });

    reducersBuilder.addCase(getLastSendBatchStats.pending, (state) => {
      state.isLoading = true;
      state.isLastSendBatchStatsLoading = true;
    });
    reducersBuilder.addCase(getLastSendBatchStats.rejected, (state) => {
      state.isLoading = false;
      state.isLastSendBatchStatsLoading = false;
      state.error = true;
      state.lastSendBatchStats = null;
    });
    reducersBuilder.addCase(getLastSendBatchStats.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isLastSendBatchStatsLoading = false;
      if (payload) {
        state.lastSendBatchStats = payload;
      }
    });

    reducersBuilder.addCase(getCampaignInvitations.pending, (state) => {
      state.isInvitesLoading = true;
    });
    reducersBuilder.addCase(getCampaignInvitations.rejected, (state) => {
      state.isInvitesLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getCampaignInvitations.fulfilled, (state, { payload }) => {
      state.isInvitesLoading = false;
      if (payload) {
        state.invitations = payload;
      }
    });
  },
});

export const {
  goToSelectedPage,
  updateTable,
  setCampaignsSorting,
  updateRequestsSize,
  reset,
  updateSearch,
  setIsCreationCampaignModal,
  resetCurrentRewardCampaign,
  resetCurrentCampaignUsers,
  goToSelectedPageExistingCampaignUsersTable,
  setExistingCampaignUsersTableSorting,
  updateExistingCampaignUsersTableSize,
  updateExistingCampaignUsersTableSearch,
  resetUserIncentiveCampaigns,
  setInvitationBatchesSorting,
  updateInvitationBatchesSize,
  updateInvitationBatchesSearch,
  openCampaignSummaryUserDetails,
  openCampaignSummaryUserDetailsRecentVideos,
  closeCampaignSummaryUserDetails,
  updateCampaignActiveInvitationsSearch,
  setCampaignUnsentInvitationsSorting,
  setCampaignUnsentInvitationsPagination,
  updateCampaignUnsentInvitationsSearch,
  updateCampaignUsersLoading,
  updateCampaignQueuedInvitationsSearch,
  setQueuedInvitationsSorting,
  openCampaignSummaryVideoDetails,
  closeCampaignSummaryVideoDetails,
  updateInviteesCountData,
  updateVideoThumbnail,
  setActiveUserInvitedCampaignsCount,
  resetActiveUserInvitedCampaignsCount,
  setLatestAddedCampaignUserId,
  setCreatorsTableActiveTabIndex,
  setTablesActiveTabIndex,
  setAllItemsChecked,
  resetVisitors,
  setOpenAddCustomersModalTrigger,
  setIsDefaultCampaignCreating,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;

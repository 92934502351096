import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Checkbox, Typography } from '@mui/material';
import { format, compareAsc as compareDates, differenceInDays } from 'date-fns';
import { FC, ReactElement } from 'react';
import {
  CampaignInvitation,
  CampaignsApiModel,
  CampaignUser,
  UserIncentiveCampaignsApiModel,
} from '../../api/models/campaigns';
import { ClientTypes } from '../../api/models/common';
import { VideoApiModel } from '../../api/models/videos';
import {
  GetTransactionAPIModel,
  ManualRecord,
  TransactionCommunicationDetails,
  TransactionCommunicationHistory,
  TransactionRecord,
} from '../../api/models/transaction';
import { UserApiModel } from '../../api/models/users';
import { VenueApiModelWithStats } from '../../api/models/venue';
import { TableContent, TableRow } from '../../common/commonTypes';
import { stringIsDate } from '../../common/components/DataSync/MatchProperties/MatchProperties.helper';
import { FavoriteButton } from '../../common/components/favoriteButton/FavoriteButton';
import { PhoneNumberDisplayed } from '../../common/components/NewDetailsModal/DetailsModalContent/salesComponents/SalesHeroPhoneBlock';
import { StatusComponentNoEdit } from '../../common/components/NewDetailsModal/DetailsModalContent/salesComponents/StatusComponent/StatusComponentNoEdit';
import { StatusDropdown } from '../../common/components/StatusDropdown/StatusDropdown';
import {
  DefaultImage,
  TextSpan,
  WrapperWithDots,
} from '../../common/components/table/CommonTable.style';
import { ImageComponent } from '../../common/components/table/TableImage';
import { VideoStatus } from '../../common/components/VideosDetailHeaderToolbar/VideoStatus';
import { OrderDirection } from '../../common/constants/constants';
import {
  CampaignsPageStrings,
  ContentListView,
  DataSyncStrings,
  HelpersStrings,
  HeroesListView,
  RewardsStrings,
  RewardsLeaderboard,
  RewardsPageStrings,
  SuperAdminPerformance,
  TeamSettingsStrings,
  UsersTable,
  CampaignTableStrings,
  DetailsSidebarStrings,
  InvitesLayoutStrings,
} from '../../common/localization/en';
import { CampaignsStatusTable } from '../../pages/campaigns/TableSection/CampaignsStatusTable/CampaignsStatusTable';
import {
  HeroesNameCell,
  LeaderboardNameCell,
  UserNameCell,
} from '../../pages/content/tableSection/contentTable/UserNameCell';
import { RewardsOwed } from '../../pages/heroes/tableSection/heroesTable/RewardsOwed';
import { UserStatusMarker } from '../../pages/heroes/tableSection/heroesTable/UserStatusMarker';
import { ResendInviteBtn } from '../../pages/NewCampaign/InvitedCustomers/ResendInviteBtn';
import { CampaignStatusCell } from '../../pages/rewards/campaignsTab/TableSection/CampaignStatusCell/CampaignStatusCell';
import { DownloadCampaignsButton } from '../../pages/rewards/campaignsTab/TableSection/DownloadButton/DownloadButton';
import { EndCampaignButton } from '../../pages/rewards/campaignsTab/TableSection/EndButton/EndButton';
import { AddVideoToSocialVerse } from '../../pages/SocialVerse/AddVideos/AddVideosButton/AddVideoToSocialVerse';
import { ApproveBtn } from '../../pages/webappSettings/DataSync/TableSection/DNDDataSyncTable.helper';
import { theme } from '../../theme';
import {
  formatDuration,
  // capitalizeWord,
  formatIntPhoneNumber,
  formatSourceBadge,
  getCampaignGoalData,
  /*  getDaysPassedFromDate, */
  getDisplayName,
  getKeywordScore,
  getLocalDateString,
  getLocaleNumberValue,
  // getMonthsPassedFromDate,
  getStringValue,
  getVideoPoster,
  numberWithCommas,
} from '../utilities';
import { RoundedButton } from '../../common/components/Button/RoundedButton';
import { RewardCampaignsEvents } from '../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../hooks/useTrackEvent';
import { CreatorName } from '../../common/components/CreatorName/CreatorName';
import { getInitials } from '../../common/components/iconWithInitials/iconWithInitials.helper';
import { Tooltip as TableTooltip } from '../../common/components/table/Tooltip';
import CheckboxIcon from '../../common/assets/CheckboxIcon';
import CheckedCheckboxIcon from '../../common/assets/CheckedCheckboxIcon';
import { CircularCheckMarkIcon } from '../../common/assets/newDesign/CircularCheckMark';
import { ErrorCircleTableIcon } from '../../common/assets/newDesign/ErrorCircleTableIcon';
import { RewardProgress } from '../../common/components/RewardProgress/RewardProgress';
import { Invitee } from '../../pages/RewardCampaigns/InviteesTable/InviteesTable';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from '../../common/components/Tooltip/Tooltip';
import { ClicksIconMedium } from '../../common/assets/newDesign/ClicksIconMedium';
import { EyeIcon } from '../../common/assets/EyeIcon';
import { SharesIconNew } from '../../common/assets/searchFilterIcons/SharesIconNew';
import { SocialVerseVideos } from '../../api/models/socialVerse';
import PublishedIcon from '../../common/assets/newDesign/PublishedIcon';
import UnpublishedIcon from '../../common/assets/newDesign/UnpublishedIcon';
import ModeratedIcon from '../../common/assets/newDesign/ModeratedIcon';
import PendingModerationIcon from '../../common/assets/newDesign/PendingModerationIcon';
import { UserPhoneStatusIcon } from '../../pages/content/tableSection/contentTable/UserPhoneStatusIcon';
import { PhoneVerificationHeader } from '../../pages/content/tableSection/contentTable/PhoneVerificationHeader';
import { UserPhoneNumber } from '../../pages/content/tableSection/contentTable/UserPhoneNumber';
import { CustomTooltip } from '../../pages/rewards/CampaignSummary/CampaignSummary.helper';
import { StarIcon } from '../../common/assets/StarIcon';
import StatusIndicator from '../../common/components/CreatorDetailsSlideout/CampaignActivity/StatusIndicator/StatusIndicator';
import { LoadingButton } from '@mui/lab';
import { ViewsIconNew } from '../../common/assets/searchFilterIcons/ViewsIconNew';
import { PlayFillIcon } from '../../common/assets/PlayFillIcon';
import { KeywordIcon } from '../../pages/content/tableSection/TableToolbar/icons/KeywordIcon';
import { UserStatus } from '../../common/components/UserStatus/UserStatus';

const SOURCE_TEXT_MAP: Record<string, string> = {
  QR_CODE: 'QR Code',
  SMS: 'SMS',
  URL: 'URL',
  DASHBOARD: 'Dashboard',
  INVITATION_LINK: 'Invitation Link',
  MANUAL: 'Manual',
};

interface MapperProps<T> {
  items: T[];
}

interface BonusPointsMapperProps<T> {
  items: T[];
  bonusPoints: number;
  isHealthCare?: boolean;
}

export interface ContentMapperProps<T> {
  isHealthCare?: boolean;
  items: T[];
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  hasAdditionalRow?: boolean;
  renderCheckbox?: boolean;
  isSocialVerse?: boolean;
  isConversionsHidden?: boolean;
  isFirefox?: boolean;
  isSociaVerse?: boolean;
  totalKeywords?: number;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  replace?: boolean;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
  currentSocialVerseVideos?: SocialVerseVideos[];
  activeFilter?: boolean;
}

interface CampaignMapperProps<T> {
  items: T[];
  handleOpenEditModal: (campaign: T) => void;
  handleOpenEndModal: (campaign: T) => void;
  isEditButton: boolean;
  isMobile: boolean;
}

export interface ExistedHeroesDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  verification: JSX.Element;
  status?: JSX.Element | string;
  source?: JSX.Element;
  lastVideoCreatedAt: string;
  videos?: JSX.Element | string;
  rewardsFulfilled: JSX.Element;
  shares?: JSX.Element | string;
  conversions?: string;
  totalPoints?: JSX.Element | string;
  videoCtaClicks: JSX.Element | string;
  views: JSX.Element | string;
}
export interface VisitorsDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  status: JSX.Element;
  days: JSX.Element;
  lastCheckInAt: JSX.Element;
  source: JSX.Element;
}

export interface HeroesDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  lastVideoCreatedAt?: string;
  videos?: JSX.Element | string;
  activeCampaigns?: JSX.Element | string;
  rewardsFulfilled?: JSX.Element | string;
  shares?: JSX.Element | string;
  conversions?: JSX.Element | string;
  totalPoints?: JSX.Element | string;
  videoCtaClicks?: JSX.Element | string;
}

export interface HeroesDetailedDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  createdAt: JSX.Element;
  phoneNumber?: string;
  email?: string;
  phoneNumberVerified?: JSX.Element;
}

export interface HeroesDeactivatedDataContent extends HeroesDataContent {
  deactivated?: JSX.Element | string;
  admin?: JSX.Element | string;
}

export interface DownloadCSVUserDataTable extends TableRow {
  id: string;
  name: JSX.Element;
  status: JSX.Element | string;
  lastVideoCreatedAt: string;
  videos?: string;
  rewardsFulfilled?: JSX.Element | string;
  shares?: string;
  conversions?: string;
  totalPoints?: JSX.Element | string;
  videoCtaClicks: string;
}

export interface TeamDataContent extends TableRow {
  id: string;
  avatar: JSX.Element;
  name: string;
  displayName: string;
  email: string;
  phone: string;
  returnUrl: JSX.Element;
  status: string | ReactElement;
  lastInvitationSentAt: Date | string;
}

export interface CampaignsContent extends TableRow {
  id: string;
  img: JSX.Element;
  name: string;
  status: JSX.Element;
  createdAt: string;
  endedAt: string;
  filterImg: JSX.Element;
}

export interface RedeemRequestsContent extends TableRow {
  id: string;
  name: JSX.Element;
  rewardsFulfilled: JSX.Element;
  campaignPoints: JSX.Element;
  totalPoints: JSX.Element;
}

export interface RedeemCampaignsContent extends TableRow {
  id: string;
  img?: JSX.Element;
  name: string | JSX.Element;
  status: JSX.Element;
  createdAt?: string;
  startedAt?: string;
  endedAt: string;
  achievements: string;
  downloadButton: JSX.Element | string;
  editButton: JSX.Element;
}

export interface ContentDataContent extends TableRow {
  id: string;
  video?: JSX.Element;
  createdAt: JSX.Element;
  userName: JSX.Element;
  views: JSX.Element;
  addVideo?: JSX.Element;
}

export interface DownloadCSVContentDataTableContent extends TableRow {
  id: string;
  video?: JSX.Element;
  createdAt: JSX.Element;
  userName: JSX.Element;
  views: JSX.Element;
  addVideo?: JSX.Element;
}

export interface BonusPointsModalDataContent extends TableRow {
  id: string;
  userName: JSX.Element;
  bonusPoints: JSX.Element;
}

export interface MobileContentDataFirstSlide extends TableRow {
  id: string;
  video: JSX.Element;
  createdAt: JSX.Element;
  name: JSX.Element;
}

export interface MobileContentDataSecondSlide extends TableRow {
  id: string;
  views: JSX.Element;
}

export interface DataSyncContent extends TableRow {
  id: string;
  name: JSX.Element;
  cell: JSX.Element;
  email: JSX.Element;
  transactionDate: JSX.Element;
  uploadDate: JSX.Element;
}

export interface ImportHistoryDetailsContent extends TableRow {
  id: string;
  name: JSX.Element;
  cell: JSX.Element;
  email: JSX.Element;
  transactionDate: JSX.Element;
  optDate: JSX.Element;
  status: JSX.Element;
}

export interface ImportHistoryDataSyncContent extends TableRow {
  id: string;
  name: JSX.Element;
  numRecords: JSX.Element;
  numValidRecords: JSX.Element;
  createdAt: JSX.Element;
  // approvedAt: JSX.Element;
  status: JSX.Element;
}

export interface UnsentInvitesTableContent extends TableRow {
  id: string;
  firstName: JSX.Element;
  source: JSX.Element;
  createdAt: JSX.Element;
  phoneNumber: JSX.Element;
}
export interface ImportCommunicationHistoryContent extends TableRow {
  id: string;
  date: JSX.Element;
  numOfSent: JSX.Element;
  numOfDelivered: JSX.Element;
  numOfUndelivered: JSX.Element;
  optOuts: JSX.Element;
}

export interface ImportCommunicationHistoryDetailsContent extends TableRow {
  id: string;
  date: JSX.Element;
  name: JSX.Element;
  cell: JSX.Element;
  type: JSX.Element;
  optOut: JSX.Element;
}

export interface InvitedCustomersDetailsContent extends TableRow {
  id: string;
  fullName: JSX.Element;
  phoneNumber: JSX.Element;
  email: JSX.Element;
  transactionDate: JSX.Element;
  status?: JSX.Element;
  action?: JSX.Element;
  resendInvite: JSX.Element;
}

export interface InviteesTableContent extends TableRow {
  id: string;
  resend?: JSX.Element;
  customerName: string | JSX.Element;
  transactionDate: string | JSX.Element;
  dateDelivered: string | JSX.Element;
  numberOfSends: string | JSX.Element;
  status: string | JSX.Element;
  firstAttemptAt: string | JSX.Element;
}

export const createTeamDataTableContent = ({
  items,
}: MapperProps<UserApiModel>): TableContent<TeamDataContent> => {
  const tableContent: TableContent<TeamDataContent> = {
    header: {
      avatar: {
        name: '',
        sortable: false,
      },
      name: {
        name: TeamSettingsStrings.FullName,
        sortable: true,
      },
      displayName: {
        name: TeamSettingsStrings.DisplayName,
        sortable: true,
      },
      email: {
        name: TeamSettingsStrings.Email,
        sortable: true,
      },
      phone: {
        name: TeamSettingsStrings.Phone,
        sortable: false,
      },
      returnUrl: {
        name: TeamSettingsStrings.URL,
        sortable: false,
      },
      lastInvitationSentAt: {
        name: TeamSettingsStrings.LastInvitationSentAt,
        sortable: false,
      },
      status: {
        name: TeamSettingsStrings.Status,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      avatar: (
        <Box component="span" display="flex" alignItems="center">
          {item.photoUrl ? (
            <ImageComponent imageRef={item.photoUrl} alt="Sponsor card" />
          ) : (
            <DefaultImage />
          )}
        </Box>
      ),
      name: getStringValue(item.name),
      displayName: item.displayName ?? getDisplayName(item.name),
      email: getStringValue(item.email),
      phone: getStringValue(item.phoneNumber),
      returnUrl: <WrapperWithDots>{getStringValue(item.returnUrl)}</WrapperWithDots>,
      lastInvitationSentAt: getLocalDateString(item.lastInvitationSentAt),
      status: <StatusComponentNoEdit small={true} status={item.status || 'ACTIVE'} />,
    };
  });

  return tableContent;
};

interface HeroesTableProps {
  venuType: ClientTypes;
  activeCampaign: null | CampaignsApiModel;
  approveRewardHandler: (userId: string) => void;
  toggleRedeemPointsModal: () => void;
  isRedeemPointsModal: boolean;
  renderCheckbox?: boolean;
  items: any[];
  isHealthCare?: boolean;
  tableType?: 'active' | 'pending' | 'deactivated';
}

export const createUserDataTableContent = ({
  items,
  activeCampaign,
  approveRewardHandler,
  toggleRedeemPointsModal,
  isRedeemPointsModal,
  renderCheckbox,
  isHealthCare,
  tableType,
}: HeroesTableProps): TableContent<
  HeroesDataContent | HeroesDetailedDataContent | HeroesDeactivatedDataContent
> => {
  const tableContent: TableContent<
    HeroesDataContent | HeroesDetailedDataContent | HeroesDeactivatedDataContent
  > = {
    header:
      tableType === 'pending'
        ? {
            name: {
              name: HeroesListView.Name,
              sortable: true,
            },
            phoneNumberVerified: {
              name: <PhoneVerificationHeader />,
              sortable: true,
            },
            createdAt: {
              name: HeroesListView.Column10,
              sortable: true,
              justifyContent: 'end',
            },
            phoneNumber: {
              name: HeroesListView.PhoneNumber,
              sortable: true,
              justifyContent: 'end',
            },
            email: {
              name: HeroesListView.Data6,
              sortable: true,
            },
          }
        : tableType === 'active'
        ? {
            name: {
              name: HeroesListView.Name,
              sortable: true,
            },
            phoneNumberVerified: {
              name: <PhoneVerificationHeader />,
              sortable: true,
            },
            lastVideoCreatedAt: {
              name: HeroesListView.Column4,
              sortable: true,
              justifyContent: 'end',
            },
            activeCampaigns: {
              name: HeroesListView.ActiveCampaigns,
              sortable: true,
              justifyContent: 'end',
            },
            videos: {
              name: (
                <Tooltip
                  title="Clips"
                  icon={
                    <Typography padding={0} marginBottom="-4px">
                      <PlayFillIcon color="#8B89A0" />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            videoViews: {
              name: (
                <Tooltip
                  title="Views"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <ViewsIconNew style={{ scale: '1.4' }} />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            shares: {
              name: (
                <Tooltip
                  title="Shares"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <SharesIconNew />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            videoCtaClicks: {
              name: (
                <Tooltip
                  title="Clicks"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <ClicksIconMedium color="#8B89A0" />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            // conversions: {
            //   name: 'Heroes.ListView.Column7',
            //   sortable: false,
            // },
            rewardsFulfilled: {
              name: HeroesListView.Rewarded,
              sortable: false,
              justifyContent: 'end',
            },
          }
        : {
            name: {
              name: HeroesListView.Name,
              sortable: true,
            },
            phoneNumberVerified: {
              name: <PhoneVerificationHeader />,
              sortable: true,
            },
            deactivated: {
              name: HeroesListView.Deactivated,
              sortable: true,
              justifyContent: 'end',
            },
            admin: {
              name: HeroesListView.Admin,
              sortable: true,
            },
            lastVideoCreatedAt: {
              name: HeroesListView.Column4,
              sortable: true,
              justifyContent: 'end',
            },
            activeCampaigns: {
              name: HeroesListView.ActiveCampaigns,
              sortable: true,
              justifyContent: 'end',
            },
            videos: {
              name: (
                <Tooltip
                  title="Clips"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <PlayFillIcon color="#8B89A0" />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            videoViews: {
              name: (
                <Tooltip
                  title="Views"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <ViewsIconNew style={{ scale: '1.4' }} />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            shares: {
              name: (
                <Tooltip
                  title="Shares"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <SharesIconNew />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            videoCtaClicks: {
              name: (
                <Tooltip
                  title="Clicks"
                  icon={
                    <Typography padding={0} marginBottom="-2px">
                      <ClicksIconMedium color="#8B89A0" />
                    </Typography>
                  }
                />
              ),
              sortable: true,
              justifyContent: 'end',
            },
            rewardsFulfilled: {
              name: HeroesListView.Rewarded,
              sortable: false,
              justifyContent: 'end',
            },
          },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }
  tableContent.rows = items.map((item) => {
    return tableType === 'pending'
      ? {
          id: item.id,
          name: (
            <Box>
              <HeroesNameCell
                user={item}
                renderCheckbox={renderCheckbox ? true : false}
                disableAvatar={true}
              />
              <Box m={renderCheckbox ? '-8px 0 0 36px' : undefined}>
                <UserPhoneNumber phone={item.phoneNumber} />
              </Box>
            </Box>
          ),
          phoneNumberVerified: <UserPhoneStatusIcon user={item} />,
          createdAt: item.lastVideoCreatedAt ? format(new Date(item.createdAt), 'PP') : '---',

          phoneNumber: item.phoneNumber || '---',
          email: item.email || '---',
        }
      : tableType === 'active'
      ? {
          id: item.id,
          name: (
            <Box>
              <HeroesNameCell
                user={item}
                renderCheckbox={renderCheckbox ? true : false}
                disableAvatar={true}
              />
              <Box m={renderCheckbox ? '-8px 0 0 36px' : undefined}>
                <UserPhoneNumber phone={item.phoneNumber} />
              </Box>
            </Box>
          ),
          phoneNumberVerified: <UserPhoneStatusIcon user={item} />,
          lastVideoCreatedAt: item.lastVideoCreatedAt
            ? format(new Date(item.lastVideoCreatedAt), 'PP')
            : '---',
          activeCampaigns: item.activeCampaigns ? getLocaleNumberValue(item.activeCampaigns) : '0',
          videos: item.videos ? getStringValue(item.videos) : '0',
          videoViews: item.videoViews ? getStringValue(item.videoViews) : '0',
          shares: item.shares ? getStringValue(item.shares) : '0',
          videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
          rewardsFulfilled: (
            <Box paddingRight="16px">
              {item.rewardsFulfilled ? getLocaleNumberValue(item.rewardsFulfilled) : '0'}
            </Box>
          ),
        }
      : {
          id: item.id,
          name: (
            <Box>
              <HeroesNameCell
                user={item}
                renderCheckbox={renderCheckbox ? true : false}
                disableAvatar={true}
              />
              <Box m={renderCheckbox ? '-8px 0 0 36px' : undefined}>
                <UserPhoneNumber phone={item.phoneNumber} />
              </Box>
            </Box>
          ),
          phoneNumberVerified: <UserPhoneStatusIcon user={item} />,
          deactivated: item.lastStatusChangedAt
            ? format(new Date(item.lastStatusChangedAt), 'PP')
            : '---',
          admin: item.lastStatusChangedBy
            ? `${item.lastStatusChangedByFirstName} ${item.lastStatusChangedByLastName[0]}`
            : '---',
          lastVideoCreatedAt: item.lastVideoCreatedAt
            ? format(new Date(item.lastVideoCreatedAt), 'PP')
            : '---',
          activeCampaigns: item.activeCampaigns ? getLocaleNumberValue(item.activeCampaigns) : '0',
          videos: item.videos ? getStringValue(item.videos) : '0',
          videoViews: item.videoViews ? getStringValue(item.videoViews) : '---',
          shares: item.shares ? getStringValue(item.shares) : '0',
          videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
          rewardsFulfilled: (
            <Box paddingRight="16px">
              {item.rewardsFulfilled ? getLocaleNumberValue(item.rewardsFulfilled) : '0'}
            </Box>
          ),
        };
  });
  return tableContent;
};

export const downloadCSVUserDataTable = ({
  items,
  activeCampaign,
  approveRewardHandler,
  toggleRedeemPointsModal,
  isRedeemPointsModal,
  renderCheckbox,
  isHealthCare,
}: HeroesTableProps): TableContent<DownloadCSVUserDataTable> => {
  const tableContent: TableContent<DownloadCSVUserDataTable> = {
    header: {
      name: {
        name: HeroesListView.Name,
        sortable: false,
      },
      status: {
        name: HeroesListView.Column3,
        sortable: false,
      },
      lastVideoCreatedAt: {
        name: HeroesListView.Column4,
        sortable: false,
      },
      videos: {
        name: HeroesListView.Column5,
        sortable: false,
      },
      shares: {
        name: HeroesListView.Shares,
        sortable: true,
      },
      videoViews: {
        name: HeroesListView.Views,
        sortable: false,
      },
      videoCtaClicks: {
        name: HeroesListView.Column6,
        sortable: false,
      },
      activeCampaigns: {
        name: HeroesListView.ActiveCampaigns,
        sortable: false,
      },
      rewardsFulfilled: {
        name: HeroesListView.Rewards,
        sortable: false,
      },
      phoneNumberVerified: {
        name: HeroesListView.PhoneVerified,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      name: <HeroesNameCell user={item} renderCheckbox={false} disableAvatar />,
      status: <UserStatusMarker status={item.status} />,
      lastVideoCreatedAt: item.lastVideoCreatedAt
        ? format(new Date(item.lastVideoCreatedAt), 'PP')
        : '---',
      videos: item.videos ? getStringValue(item.videos) : '0',
      shares: item.shares ? getStringValue(item.shares) : '0',
      videoViews: item.videoViews ? getStringValue(item.videoViews) : '0',
      videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
      activeCampaigns: (
        <Typography textAlign="center">
          {item.activeCampaigns ? getLocaleNumberValue(item.activeCampaigns) : '0'}
        </Typography>
      ),
      rewardsFulfilled: item.rewardsFulfilled ? getLocaleNumberValue(item.rewardsFulfilled) : '0',
      phoneNumberVerified: (
        <Typography textAlign="center">
          {item.phoneNumberVerified ? 'Verified' : 'Unverified'}
        </Typography>
      ),
    };
  });
  return tableContent;
};

interface NewRewardCampaignTableContentProps {
  venuType: ClientTypes;
  activeCampaign?: null | CampaignsApiModel;
  approveRewardHandler?: (userId: string) => void;
  toggleRedeemPointsModal?: () => void;
  isRedeemPointsModal?: boolean;
  renderCheckbox?: boolean;
  items: any[];
  isTrubluClient?: boolean;
  isInvites?: boolean;
  onDeselect?: (value: boolean) => void;
}

export interface UsersDataContent extends TableRow {
  id: string;
  name: JSX.Element;
  lastInvitationSentAt: string;
  videos?: string;
  conversions?: string;
  videoCtaClicks: string;
  shares?: string;
  rowDelete?: string;
}

export const newRewardCampaignTableContent = ({
  items,
  renderCheckbox,
  isInvites,
  isTrubluClient,
  onDeselect,
}: NewRewardCampaignTableContentProps): TableContent<UsersDataContent> => {
  const tableContent: TableContent<UsersDataContent> = {
    header: {
      name: {
        name: isTrubluClient ? HeroesListView.Column2_TruBlue : HeroesListView.UserName,
        sortable: false,
      },
      lastInvitationSentAt: {
        name: HeroesListView.TransactionDate,
        sortable: true,
      },
      stories: {
        name: HeroesListView.Column5,
        sortable: true,
      },
      videoViews: {
        name: HeroesListView.Views,
        sortable: true,
      },
      videoCtaClicks: {
        name: HeroesListView.Column6,
        sortable: true,
      },
      shares: {
        name: HeroesListView.Shares,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      name: (
        <HeroesNameCell
          user={item}
          renderCheckbox={renderCheckbox ? true : false}
          isInvites={isInvites}
          onDeselect={onDeselect}
          disableAvatar
        />
      ),
      lastInvitationSentAt: item.lastInvitationSentAt
        ? format(new Date(item.lastInvitationSentAt), 'PP')
        : '---',
      videos: item.videos ? getStringValue(item.videos) : '0',
      videoViews: item.videoViews ? getStringValue(item.videoViews) : '0',
      videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
      shares: item.shares ? getStringValue(item.shares) : '0',
    };
  });
  return tableContent;
};

interface VisitedUsersTableContentProps {
  items: CampaignUser[];
}

export const campaignVisitorsTableContent = ({
  items,
}: VisitedUsersTableContentProps): TableContent<VisitorsDataContent> => {
  const tableContent: TableContent<VisitorsDataContent> = {
    header: {
      name: {
        name: 'Customers',
        sortable: false,
      },
      status: {
        name: 'Status',
        sortable: true,
      },
      days: {
        name: 'Days in Status',
        sortable: true,
      },
      lastCheckInAt: {
        name: 'Date Visited',
        sortable: true,
        justifyContent: 'end',
      },
      source: {
        name: 'Source',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.userId,
      name: (
        <Box display="flex">
          <Box>
            <CreatorName creatorName={item.userName} showPlayIcon={false} />
            <UserPhoneNumber phone={item.phoneNumber} />
          </Box>
        </Box>
      ),
      status: (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <UserStatus status={'Visited'} />
        </Box>
      ),
      days: <>{differenceInDays(new Date(), new Date(item.createdAt))}</>,
      lastCheckInAt: (
        <Typography>
          {item.lastCheckInAt ? format(new Date(item.lastCheckInAt), 'PP') : '---'}
        </Typography>
      ),
      source: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Typography
              padding="0 14px"
              border="1px solid #175CD3"
              borderRadius="30px"
              height="22px"
              color="#175CD3 !important"
              fontSize="12px !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              SMS
            </Typography>
          </Box>
        </Box>
      ),
    };
  });
  return tableContent;
};

export const campaignCreatorsTableContent = ({
  items,
}: VisitedUsersTableContentProps): TableContent<VisitorsDataContent> => {
  const tableContent: TableContent<VisitorsDataContent> = {
    header: {
      name: {
        name: 'Customers',
        sortable: false,
      },
      status: {
        name: 'Status',
        sortable: true,
      },
      days: {
        name: 'Days in Status',
        sortable: true,
      },
      lastCheckInAt: {
        name: 'Date Visited',
        sortable: true,
        justifyContent: 'end',
      },
      source: {
        name: 'Source',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    const sourceText = SOURCE_TEXT_MAP[item.source || ''] || item.source || 'Organic';
    return {
      id: item.userId,
      name: (
        <Box display="flex">
          <Box>
            <CreatorName creatorName={item.userName} showPlayIcon={false} />
            <UserPhoneNumber phone={item.phoneNumber} />
          </Box>
        </Box>
      ),
      status: (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          <UserStatus status={'Creator'} />
        </Box>
      ),
      days: <>---</>,
      lastCheckInAt: (
        <Typography>
          {item.lastCheckInAt ? format(new Date(item.lastCheckInAt), 'PP') : '---'}
        </Typography>
      ),
      source: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Typography
              padding="0 14px"
              border="1px solid #175CD3"
              borderRadius="30px"
              height="22px"
              color="#175CD3 !important"
              fontSize="12px !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {sourceText}
            </Typography>
          </Box>
        </Box>
      ),
    };
  });
  return tableContent;
};

export const campaignAllVisitorsTableContent = ({
  items,
}: VisitedUsersTableContentProps): TableContent<VisitorsDataContent> => {
  const tableContent: TableContent<VisitorsDataContent> = {
    header: {
      name: {
        name: 'Customers',
        sortable: false,
      },
      status: {
        name: 'Status',
        sortable: true,
      },
      days: {
        name: 'Days in Status',
        sortable: true,
      },
      lastCheckInAt: {
        name: 'Date Visited',
        sortable: true,
        justifyContent: 'end',
      },
      source: {
        name: 'Source',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    const sourceText = SOURCE_TEXT_MAP[item.source || ''] || item.source || 'Organic';
    return {
      id: item.userId,
      name: (
        <Box display="flex">
          <Box>
            <CreatorName creatorName={item.userName} showPlayIcon={false} />
            <UserPhoneNumber phone={item.phoneNumber} />
          </Box>
        </Box>
      ),
      status: (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <UserStatus status={item.videos ? 'Creator' : 'Visited'} />
        </Box>
      ),
      days: <>{item.videos ? '---' : differenceInDays(new Date(), new Date(item.createdAt))}</>,
      lastCheckInAt: (
        <Typography>
          {item.lastCheckInAt ? format(new Date(item.lastCheckInAt), 'PP') : '---'}
        </Typography>
      ),
      source: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Typography
              padding="0 14px"
              border="1px solid #175CD3"
              borderRadius="30px"
              height="22px"
              color="#175CD3 !important"
              fontSize="12px !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {sourceText}
            </Typography>
          </Box>
        </Box>
      ),
    };
  });
  return tableContent;
};

interface ExistingCampaignUsersTableContentProps {
  venuType: ClientTypes;
  campaign: CampaignsApiModel;
  approveRewardHandler?: (userId: string) => void;
  renderCheckbox?: boolean;
  items: CampaignUser[];
  isTrubluClient?: boolean;
  isInvites?: boolean;
  handleTrackEvent: IEventTracker;
  isUserSlideoutOpen?: boolean;
  isLoading?: boolean;
  selectedUsers: string[];
  onCheckboxToggle: (userId: string, isChecked: boolean) => void;
  hasSelectableUsers: boolean;
}

export const existingCampaignUsersTableContent = ({
  campaign,
  items,
  renderCheckbox,
  isTrubluClient,
  isInvites,
  handleTrackEvent,
  approveRewardHandler,
  isUserSlideoutOpen,
  isLoading,
  selectedUsers,
  onCheckboxToggle,
  hasSelectableUsers,
}: ExistingCampaignUsersTableContentProps): TableContent<ExistedHeroesDataContent> => {
  const tableContent: TableContent<ExistedHeroesDataContent> = {
    header: {
      name: {
        name: HeroesListView.Name,
        sortable: true,
      },
      verification: {
        name: <PhoneVerificationHeader />,
        sortable: true,
      },
      source: {
        name: 'Source',
        sortable: true,
      },
      lastVideoCreatedAt: {
        name: HeroesListView.LastVideo,
        sortable: true,
        justifyContent: 'end',
      },
      videos: {
        name: (
          <Tooltip
            title="Clips"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <PlayFillIcon color="#8B89A0" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      views: {
        name: (
          <Tooltip
            title="Views"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <ViewsIconNew style={{ scale: '1.4' }} />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      shares: {
        name: (
          <Tooltip
            title="Shares"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <SharesIconNew />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      videoCtaClicks: {
        name: (
          <Tooltip
            title="Clicks"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <ClicksIconMedium color="#8B89A0" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },

      rewardsFulfilled: {
        name: HeroesListView.RewardFulfillment,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    const { isGoalAchieved, goalProgress, goal } = getCampaignGoalData(campaign, item);

    const sourceText = SOURCE_TEXT_MAP[item.source || ''] || item.source || 'Organic';
    return {
      id: item.userId,
      name: (
        <Box display="flex">
          {!item.fulfilled && item.phoneNumberVerified ? (
            <Checkbox
              style={{ marginRight: '13px', marginLeft: '-7px' }}
              color="primary"
              checked={!!selectedUsers.find((id) => id === item.userId)}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(_, checked) => onCheckboxToggle(item.userId, checked)}
              inputProps={{ 'aria-label': 'controlled', 'data-row-id': item.userId } as any}
              icon={<CheckboxIcon />}
              checkedIcon={<CheckedCheckboxIcon />}
              id={`${item.userName}-checkbox`}
              disabled={isLoading}
              sx={{
                height: '40px',
              }}
            />
          ) : (
            <Box ml={hasSelectableUsers ? '42px' : '0'} />
          )}
          <Box>
            <CreatorName creatorName={item.userName} showPlayIcon={false} />
            <UserPhoneNumber phone={item.phoneNumber} />
          </Box>
        </Box>
      ),
      verification: <UserPhoneStatusIcon user={item} />,
      source: (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Typography
              padding="0 14px"
              border="1px solid #175CD3"
              borderRadius="30px"
              height="22px"
              color="#175CD3 !important"
              fontSize="12px !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {sourceText}
            </Typography>
          </Box>
        </Box>
      ),
      lastVideoCreatedAt: item.lastVideoCreatedAt
        ? format(new Date(item.lastVideoCreatedAt), 'PP')
        : '---',
      videos: item.videos ? getStringValue(item.videos) : '0',
      views: item.videoViews ? getStringValue(item.videos) : '0',
      shares: item.shares ? getStringValue(item.shares) : '0',
      videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
      rewardsFulfilled: item.phoneNumberVerified ? (
        campaign && isGoalAchieved && !item.fulfilled ? (
          <RoundedButton
            onClick={(event) => {
              event.stopPropagation();
              approveRewardHandler?.(item.userId);
              handleTrackEvent(
                campaign.isNullReward
                  ? RewardCampaignsEvents.RewardCampaginAcknowledgeNow
                  : RewardCampaignsEvents.RewardCampaginRewardNowClick,
              );
            }}
            variant="contained"
            height="23px"
            title={campaign.isNullReward ? UsersTable.AcknowledgeNow : UsersTable.RewardNow}
            id={`${item.userName?.split(' ').join('-')}-reward-now-button`}
          />
        ) : item.fulfilled ? (
          <Typography
            style={{
              textAlign: 'center',
              color: '#8B89A0',
              fontSize: '13px',
              fontWeight: '600',
              paddingLeft: '12px',
            }}
          >
            {campaign?.isNullReward ? UsersTable.Acknowledged : UsersTable.Rewarded}
          </Typography>
        ) : (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '95%',
            }}
          >
            <RewardProgress goal={goal} progress={goalProgress} />
          </Box>
        )
      ) : (
        <></>
      ),
    };
  });
  return tableContent;
};

interface InviteesTableContentProps {
  items: Invitee[];
  isHealthCare?: boolean;
  handleResend: (inviteId: string) => Promise<void>;
  isUndeliveredInvites: boolean;
  isActiveCampaign: boolean;
}

export const inviteesTableContent = ({
  items,
  isHealthCare,
  handleResend,
  isUndeliveredInvites,
  isActiveCampaign,
}: InviteesTableContentProps): TableContent<InviteesTableContent> => {
  const tableContent: TableContent<InviteesTableContent> = {
    header: {
      resend: {
        name: '',
        sortable: false,
      },
      customerName: {
        name: isHealthCare ? InvitesLayoutStrings.Patient : InvitesLayoutStrings.Customer,
        sortable: true,
      },
      transactionDate: {
        name: InvitesLayoutStrings.TransactionDate,
        sortable: true,
      },
      firstAttemptAt: {
        name: InvitesLayoutStrings.FirstInvitation,
        sortable: true,
        disabled: isUndeliveredInvites,
      },
      dateDelivered: {
        name: isUndeliveredInvites
          ? InvitesLayoutStrings.DateAttempted
          : InvitesLayoutStrings.DateDelivered,
        sortable: true,
      },
      numberOfSends: {
        name: InvitesLayoutStrings.InvitationsSent,
        sortable: true,
      },
      status: {
        name: InvitesLayoutStrings.Status,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }
  tableContent.rows = items.map((item) => {
    const status = item.smsOptedIn ? item.status : 'Opted-Out';

    return {
      id: item.id,
      resend: <></>,
      customerName: (
        <Box ml="3px">
          <Typography>{item.customerName}</Typography>
          <UserPhoneNumber phone={item.cellNumber} />
        </Box>
      ),
      transactionDate: <Typography>{item.transactionDate}</Typography>,
      firstAttemptAt: <Typography>{item.firstAttemptAt}</Typography>,
      dateDelivered: (
        <Typography>{isUndeliveredInvites ? item.lastInviteSent : item.dateDelivered}</Typography>
      ),
      numberOfSends: (
        <Typography textAlign="center" paddingLeft="60px">
          {item.numberOfSends}
        </Typography>
      ),

      status: (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          ml={status === 'Pending' ? '26px' : '0'}
          paddingRight="12% !important"
        >
          <Typography
            sx={{
              width: '90px',
              height: '20px',
              background: status === 'Undelivered' ? 'none' : 'rgb(239, 248, 255)',
              borderRadius: '30px',
              textAlign: 'center',
              fontSize: '12px',
              color: status === 'Undelivered' ? '#F3B03E' : '#175CD3',
              letterSpacing: '0.02em',
            }}
          >
            {status}
          </Typography>
          {status === 'Pending' && (
            <Tooltip
              content={
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box fontWeight="600" color="#344054">
                    {CampaignTableStrings.VideoAppLogin}
                  </Box>
                  <Box mt="5px">{item.lastCheckInAt}</Box>
                </Box>
              }
            />
          )}
        </Box>
      ),
    };
  });
  return tableContent;
};

interface UnsentInvitesTableProps {
  items: CampaignInvitation[];
  selectedItems: Array<CampaignInvitation['id']>;
  onCheckboxToggle?: (item: CampaignInvitation, value: boolean) => void;
  isHealthCare?: boolean;
  isLoading?: boolean;
}

export const unsentInvitesTableContent = ({
  items,
  selectedItems,
  onCheckboxToggle,
  isHealthCare,
  isLoading,
}: UnsentInvitesTableProps): TableContent<UnsentInvitesTableContent> => {
  const tableContent: TableContent<UnsentInvitesTableContent> = {
    header: {
      firstName: {
        name: DataSyncStrings.Name,
        sortable: true,
      },
      source: {
        name: 'Source',
        sortable: true,
      },
      createdAt: {
        name: 'Date Uploaded',
        sortable: true,
      },
      phoneNumber: {
        name: 'Cell Number',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      firstName: (
        <Box>
          <Checkbox
            style={{ marginLeft: '-10px', marginRight: '10px' }}
            color="primary"
            checked={!!selectedItems.find((id) => id === item.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(event, checked) => onCheckboxToggle?.(item, checked)}
            inputProps={{ 'aria-label': 'controlled', 'data-row-id': item.id } as any}
            icon={<CheckboxIcon />}
            checkedIcon={<CheckedCheckboxIcon />}
            id={`${item.firstName}-${item.lastName}-checkbox`}
            disabled={isLoading}
            sx={{
              height: '40px',
            }}
          />
          {`${item.firstName} ${item.lastName}`}
        </Box>
      ),
      source: (
        <Box display="flex" justifyContent="center">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '30px',
              width: 'fit-content',
              height: '24px',
            }}
          >
            <Typography
              padding="0 14px"
              border="1px solid #175CD3"
              borderRadius="30px"
              height="22px"
              color="#175CD3 !important"
              fontSize="12px !important"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {formatSourceBadge(item.source)}
            </Typography>
          </Box>
        </Box>
      ),
      createdAt: (
        <Typography textAlign="end" paddingRight="39%">
          {item.createdAt ? format(new Date(item.createdAt), 'PP') : ''}
        </Typography>
      ),
      phoneNumber: <Typography>{formatIntPhoneNumber(item.phoneNumber)}</Typography>,
    };
  });
  return tableContent;
};

export const selectedUsersTableContent = ({
  items,
  renderCheckbox,
  isTrubluClient,
  isInvites,
}: NewRewardCampaignTableContentProps): TableContent<UsersDataContent> => {
  const tableContent: TableContent<UsersDataContent> = {
    header: {
      name: {
        name: isTrubluClient ? HeroesListView.Column2_TruBlue : HeroesListView.UserName,
        sortable: false,
        width: '20%',
      },
      lastInvitationSentAt: {
        name: HeroesListView.TransactionDate,
        sortable: true,
      },
      videos: {
        name: HeroesListView.Column5,
        sortable: true,
      },
      videoViews: {
        name: HeroesListView.Views,
        sortable: true,
      },
      videoCtaClicks: {
        name: HeroesListView.Column6,
        sortable: true,
      },
      shares: {
        name: HeroesListView.Shares,
        sortable: true,
      },
      rowDelete: {
        name: '',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    if (!item.name) {
      item = { ...item, ...{ name: `${item.firstName || ''} ${item.lastName || ''}` } };
    }
    return {
      id: item.id,
      name: <Typography fontSize={'12px'}>{item.name}</Typography>,
      lastInvitationSentAt: item.lastInvitationSentAt
        ? format(new Date(item.lastInvitationSentAt), 'PP')
        : '---',
      videos: item.videos ? getStringValue(item.videos) : '0',
      videoViews: item.videoViews ? getStringValue(item.videoViews) : '0',
      videoCtaClicks: item.videoCtaClicks ? getLocaleNumberValue(item.videoCtaClicks) : '0',
      shares: item.shares ? getStringValue(item.shares) : '0',
      rowDelete: ' ',
    };
  });
  return tableContent;
};

export const createCampaignsTableContent = ({
  items,
}: MapperProps<CampaignsApiModel>): TableContent<CampaignsContent> => {
  const tableContent: TableContent<CampaignsContent> = {
    header: {
      img: {
        name: CampaignsPageStrings.Image,
        sortable: false,
      },
      name: {
        name: CampaignsPageStrings.CampaignName,
        sortable: false,
      },
      status: {
        name: CampaignsPageStrings.Status,
        sortable: true,
      },
      createdAt: {
        name: CampaignsPageStrings.StartDate,
        sortable: true,
      },
      endedAt: {
        name: CampaignsPageStrings.EndDate,
        sortable: true,
      },
      filterImg: {
        name: CampaignsPageStrings.Filter,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      img: item.imageUrl ? (
        <ImageComponent imageRef={item.imageUrl} alt="user" />
      ) : (
        <DefaultImage />
      ),
      name: getStringValue(item.name),
      status: <CampaignsStatusTable status={item.published} />,
      createdAt: getLocalDateString(item.createdAt),
      endedAt: getLocalDateString(item.endedAt),
      filterImg: item.filterUrl ? (
        <ImageComponent
          imgViewBottom
          borderRadius="50%"
          border="4px solid #EEEEF6"
          imageRef={item.filterUrl}
          alt="user"
        />
      ) : (
        <DefaultImage />
      ),
    };
  });
  return tableContent;
};

export const createRewardsCampaignsTableContent = (
  props: CampaignMapperProps<CampaignsApiModel>,
): TableContent<RedeemCampaignsContent> => {
  const { items, handleOpenEditModal, handleOpenEndModal, isMobile } = props;
  const tableContent: TableContent<RedeemCampaignsContent> = {
    header: {
      ...(!isMobile
        ? {
            img: {
              name: RewardsPageStrings.CampaignsHeaderPhoto,
              sortable: false,
            },
          }
        : {}),
      status: {
        name: RewardsPageStrings.CampaignsHeaderStatus,
        sortable: false,
      },
      name: {
        name: RewardsPageStrings.CampaignsHeaderNameReward,
        sortable: false,
      },

      startedAt: {
        name: RewardsPageStrings.CampaignsHeaderStartDate,
        sortable: true,
      },
      endedAt: {
        name: RewardsPageStrings.CampaignsHeaderEndDate,
        sortable: true,
      },
      numAchievements: {
        name: RewardsPageStrings.CampaignsHeaderAchievements,
        sortable: true,
      },
      downloadButton: {
        name: RewardsPageStrings.CampaignsHeaderActions,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      ...(!isMobile
        ? {
            img: item.imageUrl ? (
              <ImageComponent imageRef={item.imageUrl} alt="user" />
            ) : (
              <DefaultImage />
            ),
          }
        : {}),
      status: <CampaignStatusCell isActive={!item.endedAt} />,
      name: getStringValue(item.name),
      startedAt: getLocalDateString(item.startedAt),
      endedAt: item.endedAt ? getLocalDateString(item.endedAt) : ' ',
      achievements: getLocaleNumberValue(item.numAchievements),
      downloadButton: item.endedAt ? (
        <DownloadCampaignsButton id={item.id} name={item.name} />
      ) : (
        <EndCampaignButton onClick={handleOpenEndModal} />
      ),
      editButton: !item.endedAt ? <EditIcon onClick={() => handleOpenEditModal(item)} /> : <></>,
    };
  });

  return tableContent;
};

export const createRewardsRequestTableContent = (props: {
  items: UserApiModel[];
  onRewardClick: (userId: string) => void;
  activeCampaign: CampaignsApiModel | null;
  renderCheckbox: boolean;
  approveRewardHandler?: (userId: string) => void;
  toggleRedeemPointsModal?: () => void;
  isRedeemPointsModal?: boolean;
}): TableContent<RedeemRequestsContent> => {
  const {
    items,
    activeCampaign,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
  } = props;
  const tableContent: TableContent<RedeemRequestsContent> = {
    header: {
      name: {
        width: '40%',
        name: RewardsLeaderboard.ListColumn1,
        sortable: false,
      },
      phoneNumber: {
        width: '15%',
        name: RewardsLeaderboard.ListColumn2,
        sortable: false,
      },
      campaignPoints: {
        width: '15%',
        name: RewardsLeaderboard.ListColumnCampaignPoints,
        sortable: true,
        sortOrder: OrderDirection.DESC,
      },
      totalPoints: {
        width: '15%',
        name: RewardsLeaderboard.ListColumn3,
        sortable: true,
      },
      rewardsFulfilled: {
        width: '200px',
        name: RewardsLeaderboard.ListColumn5,
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      name: <LeaderboardNameCell renderCheckbox={Boolean(props.renderCheckbox)} user={item} />,
      phoneNumber: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            '& input': {
              textAlign: 'center',
              fontWeight: '400 !important',
            },
          }}
        >
          <PhoneNumberDisplayed blockText={item.phoneNumber} />
        </Box>
      ),
      campaignPoints: (
        <Typography paddingLeft={'10px'}>{getLocaleNumberValue(item.campaignPoints)}</Typography>
      ),
      totalPoints: (
        <Typography paddingLeft={'10px'}>{getLocaleNumberValue(item.totalPoints)}</Typography>
      ),
      rewardsFulfilled: (
        <RewardsOwed
          activeCampaign={activeCampaign}
          user={item}
          isRedeemPointsModal={isRedeemPointsModal ? isRedeemPointsModal : false}
          approveRewardHandler={approveRewardHandler ? approveRewardHandler : () => {}}
          toggleRedeemPointsModal={toggleRedeemPointsModal ? toggleRedeemPointsModal : () => {}}
        />
      ),
    };
  });
  return tableContent;
};

export const createContentDataTableContent = ({
  items,
  renderCheckbox,
  isSocialVerse,
  handleAddVideoToCard,
  isHealthCare,
  isConversionsHidden,
  isFirefox = false,
  totalKeywords = 0,
  replace,
  handleVideoReplace,
  currentSocialVerseVideos,
  activeFilter,
}: ContentMapperProps<VideoApiModel>): TableContent<ContentDataContent> => {
  const tableContent: TableContent<ContentDataContent> = {
    header: {
      userName: {
        name: ContentListView.Name,
        sortable: true,
        width: '20%',
      },
      createdAt: {
        name: ContentListView.Column3,
        sortable: true,
        width: '10%',
        justifyContent: 'end',
      },
      campaignName: {
        name: ContentListView.CampaignName,
        sortable: true,
        width: '20%',
      },
      duration: {
        name: ContentListView.VideoLength,
        sortable: true,
        justifyContent: 'end',
      },
      views: {
        name: (
          <Tooltip
            title="Views"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <EyeIcon color="#8B89A0" width="27px" height="28px" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      shares: {
        name: (
          <Tooltip
            title="Shares"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <SharesIconNew />
              </Typography>
            }
          />
        ),

        sortable: true,
        justifyContent: 'end',
      },
      ctaClicks: {
        name: (
          <Tooltip
            title="Clicks"
            icon={
              <Typography padding={0} marginBottom="-2px">
                <ClicksIconMedium color="#8B89A0" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      /*       convertedReferrals: {
        name: ContentListView.Column7,
        sortable: true,
        disabled: isConversionsHidden,
      }, */
      keywordScore: {
        name: (
          <Tooltip
            title={ContentListView.Column9}
            icon={
              <Typography padding={0} marginBottom="-2px">
                <KeywordIcon fill="#8B89A0" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },
      status: {
        name: (
          <Box>
            <Typography color="#8B89A0" fontSize={14}>
              {ContentListView.Column8}
            </Typography>
            <Tooltip
              content={
                <>
                  <Typography fontWeight={600} fontSize={12} marginBottom="6px">
                    {ContentListView.Column8}:
                  </Typography>
                  <Box display="flex" flexDirection="column" gap="5px">
                    <Box display="flex" alignItems="center" gap="6px">
                      <PublishedIcon /> {ContentListView.Published}
                    </Box>
                    <Box display="flex" alignItems="center" gap="6px">
                      <UnpublishedIcon /> {ContentListView.Unpublished}
                    </Box>
                    <Box display="flex" alignItems="center" gap="6px">
                      <ModeratedIcon /> {ContentListView.Moderated}
                    </Box>
                    <Box display="flex" alignItems="center" gap="6px">
                      <PendingModerationIcon /> {ContentListView.PendingModeration}
                    </Box>
                  </Box>
                </>
              }
            />
          </Box>
        ),
        sortable: true,
      },
      ...(isSocialVerse
        ? {
            addVideo: {
              name: 'Add Clip',
              sortable: false,
            },
          }
        : {}),
    },
    rows: [],
  };
  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item, index) => ({
    id: item.id,
    userName: (
      <UserNameCell
        renderCheckbox={
          renderCheckbox &&
          !currentSocialVerseVideos?.some((v: SocialVerseVideos) => v.videoId === item.id)
            ? true
            : false
        }
        activeFilter={!isSocialVerse && activeFilter}
        video={item}
        renderAvatar={false}
        showPhoneNumber
        style={
          isFirefox
            ? { marginLeft: '0px !important', marginBottom: '-10px !important' }
            : isSocialVerse
            ? { marginLeft: '-10px !important' }
            : { marginLeft: '-35px !important' }
        }
      />
    ),
    createdAt: <TextSpan>{item.createdAt ? format(new Date(item.createdAt), 'PP') : ''} </TextSpan>,
    campaignName: <TextSpan>{item.campaignName}</TextSpan>,
    duration: <TextSpan>{formatDuration(item.duration, { twoDigitsMinutes: true })}</TextSpan>,
    views: <TextSpan>{getStringValue(item.views)} </TextSpan>,
    shares: <TextSpan>{item.shares} </TextSpan>,
    ctaClicks: <TextSpan>{item.ctaClicks}</TextSpan>,
    /*     convertedReferrals: <TextSpan>{item.convertedReferrals}</TextSpan>, */
    keywordScore: (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          width: 'auto',
          margin: '0 auto',
        }}
      >
        <TextSpan
          style={{ marginRight: '-2px', fontSize: '13px', fontWeight: '500', color: '#667085' }}
        >
          {getKeywordScore(totalKeywords, item.keywordsCount || 0) === '0%'
            ? '---'
            : getKeywordScore(totalKeywords, item.keywordsCount || 0)}
          &nbsp;
        </TextSpan>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {getKeywordScore(totalKeywords, item.keywordsCount || 0) !== '0%' && (
            <Tooltip
              content={
                <>
                  <Typography style={{ fontSize: '11px', fontWeight: '500', color: '#667085' }}>
                    Transcript contains
                  </Typography>
                  <Typography style={{ fontSize: '11px', fontWeight: '500', color: '#667085' }}>
                    {item.keywordsCount} of {totalKeywords} Keywords
                  </Typography>
                </>
              }
              icon={
                <HelpOutlineIcon color="disabled" style={{ height: '20px', marginBottom: '2px' }} />
              }
              disablePortal
              width="140px"
              followCursor={false}
            ></Tooltip>
          )}
        </Box>
      </Box>
    ),

    status: <VideoStatus status={item.status} />,
    ...(isSocialVerse
      ? {
          addVideo: (
            <AddVideoToSocialVerse
              id={item.id}
              handleClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                return replace
                  ? handleVideoReplace
                    ? handleVideoReplace({ event, id: item.id, addUsingRowBtn: true })
                    : null
                  : handleAddVideoToCard
                  ? handleAddVideoToCard(event, item.id, true)
                  : null;
              }}
              buttonIndex={index}
            />
          ),
        }
      : {}),
  }));

  return tableContent;
};
export const downloadCSVContentDataTable = ({
  items,
  renderCheckbox,
  isSocialVerse,
  handleAddVideoToCard,
  isHealthCare,
  isConversionsHidden,
  totalKeywords = 0,
}: ContentMapperProps<VideoApiModel>): TableContent<DownloadCSVContentDataTableContent> => {
  const tableContent: TableContent<DownloadCSVContentDataTableContent> = {
    header: {
      userName: {
        name: ContentListView.Name,
        sortable: true,
      },
      createdAt: {
        name: ContentListView.Column3,
        sortable: true,
      },
      shares: {
        name: ContentListView.ColumnShares,
        sortable: true,
      },
      views: {
        name: ContentListView.Column5,
        sortable: true,
      },
      ctaClicks: {
        name: ContentListView.Column6,
        sortable: true,
      },
      convertedReferrals: {
        name: ContentListView.Column7,
        sortable: true,
        disabled: isConversionsHidden,
      },
      keywordsCount: {
        name: ContentListView.Column11,
        sortable: true,
      },
      keywordScore: {
        name: ContentListView.Column9,
        sortable: true,
      },
      status: {
        name: ContentListView.Column8,
        sortable: true,
      },
      ...(isSocialVerse
        ? {
            addVideo: {
              name: 'Add Clip',
              sortable: false,
            },
          }
        : {}),
    },
    rows: [],
  };
  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item, index) => ({
    id: item.id,
    userName: <UserNameCell renderCheckbox={false} video={item} renderAvatar={false} />,
    createdAt: <TextSpan>{item.createdAt ? format(new Date(item.createdAt), 'PP') : ''} </TextSpan>,
    shares: <TextSpan>{item.shares} </TextSpan>,
    views: <TextSpan>{getStringValue(item.views)} </TextSpan>,
    ctaClicks: <TextSpan>{item.ctaClicks}</TextSpan>,
    convertedReferrals: <TextSpan>{item.convertedReferrals}</TextSpan>,
    keywordsCount: <TextSpan>{item.keywordsCount || 0}</TextSpan>,
    keywordScore: <TextSpan>{getKeywordScore(totalKeywords, item.keywordsCount || 0)}</TextSpan>,
    status: <VideoStatus status={item.status} />,
    ...(isSocialVerse
      ? {
          addVideo: (
            <AddVideoToSocialVerse
              id={item.id}
              handleClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                return handleAddVideoToCard ? handleAddVideoToCard(event, item.id, true) : null;
              }}
              buttonIndex={index}
            />
          ),
        }
      : {}),
  }));

  return tableContent;
};

export enum InviteStatus {
  Active = 'ACTIVE',
  LinkClicked = 'LINK_CLICKED',
  InviteSent = 'INVITE_SENT',
}

export const createInvitedCustomersDataTableContent = ({
  items,
  isTrubluClient,
  handleTrackEvent,
  isFirefox,
}: {
  items: TransactionRecord[];
  isTrubluClient: boolean;
  handleTrackEvent: IEventTracker;
  isFirefox?: boolean;
}): TableContent<InvitedCustomersDetailsContent> => {
  const tableContent: TableContent<InvitedCustomersDetailsContent> = {
    header: {
      fullName: {
        name: `${isTrubluClient ? 'Patient' : 'Creator'} Name`,
        sortable: true,
      },
      transactionDate: {
        name: 'Invite Sent',
        sortable: true,
        width: '200px',
      },
      phoneNumber: {
        name: 'Cell Phone',
        sortable: true,
      },
      email: {
        name: 'Email',
        sortable: true,
      },
      resendInvite: {
        name: 'Resend Invite',
        sortable: false,
      },
    },
    rows: [],
  };
  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id!,
      fullName: <CreatorName showPlayIcon creatorName={item.fullName || ''} />,
      transactionDate: (
        <TextSpan>
          {item.derivedTransactionDate
            ? !isFirefox
              ? format(new Date(item.derivedTransactionDate.replace('-', '/')), 'PP')
              : format(new Date(item.derivedTransactionDate), 'PP')
            : ''}
        </TextSpan>
      ),
      phoneNumber: <Box>{item.phoneNumber}</Box>,
      email: <>{item.email || '---'}</>,
      resendInvite: <ResendInviteBtn record={item} handleTrackEvent={handleTrackEvent} />,
    };
  });

  return tableContent;
};

export const createContentDataTablePointsModalContent = ({
  items,
  bonusPoints,
  isHealthCare,
}: BonusPointsMapperProps<
  UserApiModel | VideoApiModel
>): TableContent<BonusPointsModalDataContent> => {
  const tableContent: TableContent<BonusPointsModalDataContent> = {
    header: {
      userName: {
        name: ContentListView.Name,
        sortable: false,
      },
      bonusPoints: {
        name: RewardsStrings.BonusPoints,
        sortable: false,
        width: '80px',
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    let src = '';
    let name = '';
    if ('photoUrl' in item) {
      src = item.photoUrl || '';
    } else if ('url' in item) {
      src = getVideoPoster(item.url);
    }
    if ('userName' in item) {
      name = item.userName || '-';
    } else if ('name' in item) {
      name = item.name ?? '-';
    }
    return {
      id: (item as VideoApiModel).userId || item.id,
      userName: (
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={src}
            width="35px"
            height="45px"
            borderRadius="6px"
            marginRight="16px"
          />
          <TextSpan>{name}</TextSpan>
        </Box>
      ),
      bonusPoints: (
        <Typography sx={{ width: '100%', textAlign: 'center', marginLeft: '-10px' }}>
          {numberWithCommas(bonusPoints)}
        </Typography>
      ),
    };
  });

  return tableContent;
};

export const createMobileContentDataTableContentFirstSlide = ({
  items,
}: MapperProps<VideoApiModel>): TableContent<MobileContentDataFirstSlide> => {
  const tableContent: TableContent<MobileContentDataFirstSlide> = {
    header: {
      video: {
        name: ContentListView.Column1,
        sortable: false,
      },
      userName: {
        name: ContentListView.Column2,
        sortable: true,
      },
      createdAt: {
        name: ContentListView.Column4,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => ({
    id: item.id,
    video: (
      <Box component="span" display="flex" alignItems="center">
        {item.url ? <ImageComponent imageRef={getVideoPoster(item.url)} /> : <DefaultImage />}
      </Box>
    ),
    name: <TextSpan>{item.userName ? getStringValue(item.userName) : '-'}</TextSpan>,
    createdAt: <TextSpan>{getLocalDateString(item.createdAt)} </TextSpan>,
  }));

  return tableContent;
};

export const createMobileContentDataTableContentSecondSlide = ({
  items,
  handleOpenConfirmationModal,
}: ContentMapperProps<VideoApiModel>): TableContent<MobileContentDataSecondSlide> => {
  const tableContent: TableContent<MobileContentDataSecondSlide> = {
    header: {
      views: {
        name: ContentListView.Column5,
        sortable: true,
      },
      featured: {
        name: ContentListView.Column9,
        sortable: true,
      },
      status: {
        name: ContentListView.Column8,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => ({
    id: item.id,
    views: <TextSpan>{getStringValue(item.views)} </TextSpan>,
    favorite: (
      <span>
        <FavoriteButton
          checked={item.featured}
          id={item.id}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
        />
      </span>
    ),
    status: (
      <StatusDropdown
        id={item.id}
        TG={false}
        publishedToWebapp={item.status ? 'published' : 'unpublished'}
      />
    ),
  }));

  return tableContent;
};

export interface VenuesListTableData extends TableRow {
  id: string;
  account: JSX.Element;
  distributorName?: JSX.Element;
  pricingPlanName: JSX.Element;
  createdAt: JSX.Element;
  // monthsActive: JSX.Element;
  archivedBy?: JSX.Element;
  deactivatedAt?: JSX.Element;
  lastCampaignInvitationAddedAt: JSX.Element;
  // users: JSX.Element;
  videos: JSX.Element;
  socialverses: JSX.Element;
  error?: string;
  numGbpVideos?: JSX.Element;
}

export const createVenuesListTableContent = (
  onOpenDashboard: (id: string) => void,
  { items }: MapperProps<VenueApiModelWithStats>,
  isAdmin: boolean,
  isArchivedAccount: boolean,
  isUwmTable: boolean,
): TableContent<VenuesListTableData> => {
  const tableContent: TableContent<VenuesListTableData> = {
    header: {
      account: {
        name: SuperAdminPerformance.Account,
        sortable: false,
        width: '5%',
      },
      pricingPlanName: {
        name: SuperAdminPerformance.PricingPlan,
        sortable: true,
        disabled: isUwmTable,
      },
      ownerName: {
        name: SuperAdminPerformance.Owner,
        sortable: false,
        disabled: !isAdmin || !isUwmTable,
        width: '8%',
      },
      soldByName: {
        name: SuperAdminPerformance.SoldBy,
        sortable: true,
        disabled: !isAdmin || isUwmTable,
      },
      ...(isAdmin && !isUwmTable
        ? {
            lastFailedBillingDate: {
              name: SuperAdminPerformance.PaymentStatus,
              sortable: true,
              disabled: isArchivedAccount,
            },
          }
        : {}),
      createdAt: {
        name: SuperAdminPerformance.DateActivated,
        sortable: true,
      },
      active: {
        name: SuperAdminPerformance.Active,
        sortable: false,
        disabled: !isAdmin || !isUwmTable,
      },
      billable: {
        name: SuperAdminPerformance.Billable,
        sortable: true,
        disabled: !isAdmin || !isUwmTable,
      },
      deactivatedAt: {
        name: SuperAdminPerformance.DateArchived,
        sortable: true,
        disabled: !isArchivedAccount,
      },
      archivedBy: {
        name: SuperAdminPerformance.ArchivedBy,
        sortable: true,
        disabled: !isArchivedAccount,
      },
      numActiveCampaigns: {
        name: 'Active Campaigns',
        sortable: true,
      },
      lastCampaignInvitationAddedAt: {
        name: SuperAdminPerformance.LastInvite,
        sortable: true,
      },
      numInvitesSent: {
        name: SuperAdminPerformance.TotalInvites,
        sortable: true,
      },
      videos: {
        name: SuperAdminPerformance.Videos,
        sortable: true,
      },
      socialverses: {
        name: SuperAdminPerformance.LiveSVs,
        sortable: true,
      },

      /* distributorName: {
        name: SuperAdminPerformance.Distributor,
        sortable: true,
        disabled: !isAdmin,
        width: '3%',
      }, */

      // monthsActive: {
      //   name: SuperAdminPerformance.MonthsActive,
      //   sortable: true,
      // },

      // users: {
      //   name: creatorsOrPatients,
      //   sortable: true,
      // },

      numPublishedVideos: {
        name: 'Clips in Live SVs',
        sortable: true,
      },

      numGbpVideos: {
        name: 'GBP-Clips Synced',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  // const MONTHS_ACTIVE_TO_HIGHLIGHT = 6;
  // const DAYS_NOT_SYNC_TO_HIGHLIGHT = 7;

  tableContent.rows = items.map((item) => {
    // const monthsActive = getMonthsPassedFromDate(item.createdAt);

    // const shouldHighlightDataSync =
    //   !item?.lastTransactionBatchSyncedAt ||
    //   getDaysPassedFromDate(item?.lastTransactionBatchSyncedAt) > DAYS_NOT_SYNC_TO_HIGHLIGHT;

    const getCardStatus = () => {
      const cardStatus = {
        DECLINED: 'Declined',
        APPROVED: 'Approved',
        NOT_AVAILABLE: HelpersStrings.NotAvailable,
      };
      if (!item.lastFailedBillingDate && !item.lastSuccessfulBillingDate) {
        return cardStatus.NOT_AVAILABLE;
      }

      // have only failed date
      if (item.lastFailedBillingDate && !item.lastSuccessfulBillingDate) {
        return cardStatus.DECLINED;
      }

      // have only success date
      if (item.lastSuccessfulBillingDate && !item.lastFailedBillingDate) {
        return cardStatus.APPROVED;
      }

      return compareDates(
        new Date(item.lastSuccessfulBillingDate ?? ''),
        new Date(item.lastFailedBillingDate ?? ''),
      ) >= 0
        ? cardStatus.APPROVED
        : cardStatus.DECLINED;
    };
    const cardStatus = getCardStatus();

    return {
      id: item.id,
      account: <AccountCell logo={item.logo} name={item.name} />,
      /*  distributorName: <CellText text={item?.distributorName || HelpersStrings.NotAvailable} />, */
      pricingPlanName: (
        <Box pr="10px">
          <CellText
            text={item?.pricingPlanName || SuperAdminPerformance.Enterprise}
            color={theme.palette.common.gray}
          />
        </Box>
      ),
      ownerName: (
        <Box pr="10px">
          {' '}
          <CellText text={item.ownerName} color={theme.palette.common.gray} />
        </Box>
      ),
      ...(isAdmin
        ? {
          soldByName: (
              <TableTooltip
                padding="20px"
                rounded="18px"
                title={item.soldByName || HelpersStrings.NotFound}
                icon={
                  <Box
                    style={{
                      borderRadius: '50%',
                      fontSize: '10px',
                      lineHeight: '18px',
                      fontWeight: '500',
                      width: '20px',
                      height: '20px',
                      background: '#F2F4F7',
                      color: '#475467',
                      padding: '0px !important',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {getInitials(item.soldByName) || <RemoveIcon />}
                  </Box>
                }
              />
            ),
          }
        : {}),
      ...(isAdmin && !isUwmTable
        ? {
            lastFailedBillingDate: (
              <Box display="flex" justifyContent="center" pr="10px">
                {cardStatus !== 'Approved' && item?.pricingPlanName ? (
                  <ErrorCircleTableIcon />
                ) : (
                  <CircularCheckMarkIcon color={'#24B947'} />
                )}
              </Box>
            ),
          }
        : {}),
      createdAt: (
        <CellText
          text={item.createdAt ? format(new Date(item.createdAt), 'PP') : '---'}
          color={theme.palette.common.gray}
        />
      ),
      active: (
        <Box width="100%" display="flex" justifyContent="center">
          <CircularCheckMarkIcon color={'#24B947'} />
        </Box>
      ),
      billable: (
        <Box width="100%" display="flex" justifyContent="center">
          {item.billable ? <CircularCheckMarkIcon color={'#24B947'} /> : '---'}
        </Box>
      ),
      deactivatedAt: (
        <CellText
          text={item.deactivatedAt ? format(new Date(item.deactivatedAt), 'PP') : '---'}
          color={theme.palette.common.gray}
        />
      ),
      archivedBy: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <TableTooltip
            padding="20px"
            rounded="18px"
            title={item?.archivedBy || HelpersStrings.NotFound}
            icon={
              <Box
                style={{
                  borderRadius: '50%',
                  fontSize: '10px',
                  lineHeight: '18px',
                  fontWeight: '500',
                  width: '20px',
                  height: '20px',
                  background: '#F2F4F7',
                  color: '#475467',
                  padding: '0px !important',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {getInitials(item?.archivedBy) || '---'}
              </Box>
            }
          />
        </Box>
      ),
      numActiveCampaigns: (
        <CellText
          text={item?.numActiveCampaigns ?? HelpersStrings.NotAvailable}
          color={theme.palette.common.gray}
        />
      ),
      // monthsActive: (
      //   <CellText
      //     text={`${monthsActive} mo`}
      //     color={monthsActive >= MONTHS_ACTIVE_TO_HIGHLIGHT ? '#5EE300' : 'default'}
      //   />
      // ),
      lastCampaignInvitationAddedAt: (
        <Box pr="20px">
          <CellText
            text={
              item?.lastCampaignInvitationAddedAt
                ? format(new Date(item?.lastCampaignInvitationAddedAt), 'PP')
                : '---'
            }
            color={theme.palette.common.gray}
          />
        </Box>
      ),
      // users: <CellText text={numberWithCommas(item.users)} />,
      numInvitesSent: (
        <CellText
          text={item?.numInvitesSent ?? HelpersStrings.NotAvailable}
          color={theme.palette.common.gray}
        />
      ),
      videos: <CellText text={numberWithCommas(item.videos)} color={theme.palette.common.gray} />,
      socialverses: (
        <CellText
          text={item?.socialverses ?? HelpersStrings.NotAvailable}
          color={theme.palette.common.gray}
        />
      ),

      numPublishedVideos: (
        <CellText
          text={item?.numPublishedVideos ?? HelpersStrings.NotAvailable}
          color={theme.palette.common.gray}
        />
      ),
      numGbpVideos: (
        <CellText
          text={item?.numGbpVideos ?? HelpersStrings.NotAvailable}
          color={theme.palette.common.gray}
        />
      ),
    };
  });

  return tableContent;
};

interface IAccountProps {
  logo: string;
  name: string;
  color?: string;
}

const AccountCell: FC<IAccountProps> = ({ name, color }) => {
  return (
    <Box display="flex" alignItems="center" minWidth="160px">
      <Typography
        sx={{
          fontSize: '12px',
          color: color || 'rgba(26, 21, 56, 1)',
          ml: '10px',
          letterSpacing: '0.02em',
          wordBreak: 'break-word',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

interface ICellTextProps {
  text: string | number;
  color?: string;
}

const CellText: FC<ICellTextProps> = ({ text, color }) => {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        color: color === 'default' || !color ? 'rgba(26, 21, 56, 1)' : color,
        letterSpacing: '0.02em',
      }}
    >
      {text}
    </Typography>
  );
};

interface TableDataSyncProps {
  items: any[];
  handleClick?: (id: string) => void;
}

export const createDataSyncTableContent = ({
  items,
}: TableDataSyncProps): TableContent<DataSyncContent> => {
  const tableContent: TableContent<DataSyncContent> = {
    header: {
      name: {
        name: 'Name',
        sortable: true,
        width: '25%',
      },
      cell: {
        name: 'Cell',
        sortable: true,
      },
      email: {
        name: 'Email',
        sortable: true,
        width: '25%',
      },
      uploadDate: {
        name: 'Upload Date',
        sortable: true,
      },
      transactionDate: {
        name: 'Transaction Date',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: '',
      name: <Typography fontSize={'12px'}>{item[2]}</Typography>,
      cell: <Typography fontSize={'12px'}>{item[3]}</Typography>,
      email: <Typography fontSize={'12px'}>{item[4]}</Typography>,
      uploadDate: (
        <Typography fontSize={'12px'}>{item[0] ? format(new Date(item[0]), 'PP') : ''}</Typography>
      ),
      transactionDate: (
        <Typography fontSize={'12px'}>{item[1] ? format(new Date(item[1]), 'PP') : ''}</Typography>
      ),
    };
  });
  return tableContent;
};

export const importHistoryDataSyncTableContent = ({
  items,
  handleClick,
}: TableDataSyncProps): TableContent<ImportHistoryDataSyncContent> => {
  const tableContent: TableContent<ImportHistoryDataSyncContent> = {
    header: {
      name: {
        name: DataSyncStrings.ImportHistoryCol1,
        sortable: true,
        width: '20%',
      },
      numRecords: {
        name: DataSyncStrings.ImportHistoryCol2,
        sortable: true,
      },
      numValidRecords: {
        name: DataSyncStrings.ImportHistoryCol3,
        sortable: true,
        width: '20%',
      },
      createdAt: {
        name: DataSyncStrings.ImportHistoryCol4,
        sortable: true,
        width: '20%',
      },
      // approvedAt: {
      //   name: DataSyncStrings.ImportHistoryCol5,
      //   sortable: true,
      // },
      status: {
        name: DataSyncStrings.ImportHistoryCol6,
        sortable: false,
        width: '20%',
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: GetTransactionAPIModel) => {
    return {
      id: item.id,
      name: <Typography fontSize={'12px'}>{item.name}</Typography>,
      numRecords: <Typography fontSize={'12px'}>{item.numRecords}</Typography>,
      numValidRecords: <Typography fontSize={'12px'}>{item.numValidRecords}</Typography>,
      createdAt: (
        <Typography
          fontSize={'12px'}
          sx={{
            paddingRight: '15px',
          }}
        >
          {item.createdAt ? format(new Date(item.createdAt), 'PP') : ''}
        </Typography>
      ),
      // approvedAt: (
      //   <Typography
      //     fontSize={'12px'}
      //     sx={{
      //       textAlign: 'center',
      //       paddingRight: '15px',
      //     }}
      //   >
      //     {item.approvedAt ? format(new Date(item.approvedAt), 'p') : ''}
      //   </Typography>
      // ),

      status: handleClick ? (
        <ApproveBtn status={item.status} handleClick={() => handleClick(item.id)} />
      ) : (
        <></>
      ),
    };
  });
  return tableContent;
};
export const importHistoryDetailsTableContent = ({
  items,
}: TableDataSyncProps): TableContent<ImportHistoryDetailsContent> => {
  const tableContent: TableContent<ImportHistoryDetailsContent> = {
    header: {
      transactionDate: {
        name: 'Transaction Date',
        sortable: true,
        width: '25%',
      },
      name: {
        name: 'Name',
        sortable: true,
      },
      cell: {
        name: 'Cell',
        sortable: true,
      },
      email: {
        name: 'Email',
        sortable: true,
        width: '25%',
      },
      optDate: {
        name: 'Opt In',
        sortable: true,
      },
      status: {
        name: 'Valid User',
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: TransactionRecord) => {
    return {
      id: item.refId,
      transactionDate: (
        <Typography
          color={item.isValid ? theme.palette.common.black : theme.palette.error.main}
          sx={{ textAlign: 'center' }}
          fontSize={'12px'}
        >
          {item.transactionDate && stringIsDate(item.transactionDate)
            ? format(new Date(item.transactionDate), 'PP')
            : '---'}
        </Typography>
      ),
      name: (
        <Typography
          color={item.isValid ? theme.palette.common.black : theme.palette.error.main}
          fontSize={'12px'}
        >{`${item.firstName} ${item.lastName}`}</Typography>
      ),
      cell: (
        <Typography
          color={item.isValid ? theme.palette.common.black : theme.palette.error.main}
          fontSize={'12px'}
        >
          {item.phoneNumber}
        </Typography>
      ),
      email: (
        <Typography
          color={item.isValid ? theme.palette.common.black : theme.palette.error.main}
          fontSize={'12px'}
        >
          {item.email}
        </Typography>
      ),
      optDate: (
        <Typography
          color={
            item.optedIn || item.isValid ? theme.palette.common.black : theme.palette.error.main
          }
          fontSize={'12px'}
          pl="20px"
        >
          {!item.optedIn ? 'Yes' : null}
        </Typography>
      ),
      status: (
        <Typography
          color={item.isValid ? theme.palette.common.black : theme.palette.error.main}
          fontSize={'12px'}
          textAlign="center"
        >
          {item.isValid ? 'Valid User' : 'Invalid User'}
        </Typography>
      ),
    };
  });
  return tableContent;
};

export const communicationHistoryTableContent = ({
  items,
}: TableDataSyncProps): TableContent<ImportCommunicationHistoryContent> => {
  const tableContent: TableContent<ImportCommunicationHistoryContent> = {
    header: {
      date: {
        name: DataSyncStrings.CommunicationHistoryCol1,
        sortable: true,
        width: '30%',
      },
      numOfSent: {
        name: DataSyncStrings.CommunicationHistoryCol2,
        sortable: true,
      },
      numOfDelivered: {
        name: DataSyncStrings.CommunicationHistoryCol3,
        sortable: true,
      },
      numOfUndelivered: {
        name: DataSyncStrings.CommunicationHistoryCol4,
        sortable: true,
      },
      optOuts: {
        name: DataSyncStrings.CommunicationHistoryCol5,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: TransactionCommunicationHistory) => {
    return {
      id: item.id,
      date: (
        <Typography fontSize={'12px'}>
          {item.date ? format(new Date(item.date), 'PP') : ''}
        </Typography>
      ),
      numOfSent: <Typography fontSize={'12px'}>{item.numOfSent}</Typography>,
      numOfDelivered: (
        <Typography textAlign="center" fontSize={'12px'}>
          {item.numOfDelivered}
        </Typography>
      ),
      numOfUndelivered: (
        <Typography textAlign="center" fontSize={'12px'}>
          {item.numOfUndelivered}
          {/*    {item.importDate ? format(new Date(item.importDate), 'PP') : ''} */} {''}
        </Typography>
      ),
      optOuts: (
        <Typography textAlign="center" fontSize={'12px'}>
          {item.optOuts}
        </Typography>
      ),
    };
  });
  return tableContent;
};

export const communicationHistoryDetailsTableContent = ({
  items,
}: TableDataSyncProps): TableContent<ImportCommunicationHistoryDetailsContent> => {
  const tableContent: TableContent<ImportCommunicationHistoryDetailsContent> = {
    header: {
      date: {
        name: DataSyncStrings.CommunicationHistoryDetailsCol1,
        sortable: true,
        width: '25%',
      },
      name: {
        name: DataSyncStrings.CommunicationHistoryDetailsCol2,
        sortable: true,
      },
      cell: {
        name: DataSyncStrings.CommunicationHistoryDetailsCol3,
        sortable: true,
        width: '25%',
      },
      type: {
        name: DataSyncStrings.CommunicationHistoryDetailsCol4,
        sortable: true,
      },
      optOut: {
        name: DataSyncStrings.CommunicationHistoryDetailsCol5,
        sortable: true,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: TransactionCommunicationDetails) => {
    return {
      id: item.id,
      date: (
        <Typography fontSize={'12px'}>
          {item.date ? format(new Date(item.date), 'PP') : ''}
        </Typography>
      ),
      name: <Typography fontSize={'12px'}>{item.name}</Typography>,
      cell: <Typography fontSize={'12px'}>{item.cell}</Typography>,
      type: (
        <Typography fontSize={'12px'}>
          {item.type}
          {/*    {item.importDate ? format(new Date(item.importDate), 'PP') : ''} */} {''}
        </Typography>
      ),
      optOut: <Typography fontSize={'12px'}>{item.optOut}</Typography>,
    };
  });
  return tableContent;
};

export interface ManualRecordsContent extends TableRow {
  firstName: JSX.Element;
  lastName: JSX.Element;
  phoneNumber: JSX.Element;
}

export const importManualRecordsTableContent = ({
  items,
}: TableDataSyncProps): TableContent<ManualRecordsContent> => {
  const tableContent: TableContent<ManualRecordsContent> = {
    header: {
      firstName: {
        name: 'First Name',
        sortable: false,
      },
      lastName: {
        name: 'Last Name',
        sortable: false,
      },
      phoneNumber: {
        name: 'Cell Phone',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: ManualRecord) => {
    return {
      id: item.id,
      firstName: (
        <Typography fontSize="12px" fontWeight="300" pl="15px">
          {item.firstName}
        </Typography>
      ),
      lastName: (
        <Typography fontSize="12px" fontWeight="300" pl="25px">
          {item.lastName}
        </Typography>
      ),
      phoneNumber: (
        <Typography fontSize="12px" color="#475467" pl="25px" fontWeight="300">
          {item.phoneNumber}
        </Typography>
      ),
    };
  });
  return tableContent;
};

interface QuickStartTableContent extends TableRow {
  name: JSX.Element;
  numValidRecords: JSX.Element;
}

export const importQuickStartExelTableContent = ({
  items,
}: TableDataSyncProps): TableContent<QuickStartTableContent> => {
  const tableContent: TableContent<QuickStartTableContent> = {
    header: {
      name: {
        name: 'Import name',
        sortable: false,
      },
      numValidRecords: {
        name: '# of Valid Records',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item: GetTransactionAPIModel) => {
    return {
      name: (
        <Typography fontSize="12px" fontWeight="300" pl="25px">
          {item.name}
        </Typography>
      ),
      numValidRecords: (
        <Typography fontSize="12px" fontWeight="300" textAlign="center">
          {item.numValidRecords}
        </Typography>
      ),
    };
  });

  return tableContent;
};

interface UserCampaignActivityProps {
  campaigns: UserIncentiveCampaignsApiModel[];
  onRewardClick: (campaignId: string) => void;
  isRewardLoading: boolean;
}

interface UserCampaignActivityTableContent extends TableRow {
  name: JSX.Element;
  status: JSX.Element;
  clips: JSX.Element;
  result: JSX.Element;
}

export const userCampaignActivityTableContent = ({
  campaigns,
  onRewardClick,
  isRewardLoading,
}: UserCampaignActivityProps): TableContent<UserCampaignActivityTableContent> => {
  const tableContent: TableContent<UserCampaignActivityTableContent> = {
    header: {
      name: {
        name: DetailsSidebarStrings.Name,
        sortable: false,
      },
      status: {
        name: DetailsSidebarStrings.Status,
        sortable: false,
      },
      clips: {
        name: DetailsSidebarStrings.Clips,
        sortable: false,
      },
      result: {
        name: DetailsSidebarStrings.Result,
        sortable: false,
      },
    },
    rows: [],
  };

  if (campaigns.length === 0) {
    return tableContent;
  }

  tableContent.rows = campaigns.map((user) => {
    return {
      name: (
        <Box style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }}>
          {user.campaign.isPreferred && (
            <CustomTooltip
              content={
                <Box>
                  <Typography
                    color="inherit"
                    style={{ fontSize: '11px', fontWeight: '700', textAlign: 'left' }}
                  >
                    {RewardsStrings.PrimaryCampaignToolTipTitle}
                  </Typography>
                  <Typography
                    color="inherit"
                    style={{
                      textAlign: 'left',
                      fontSize: '11px',
                      fontWeight: '500',
                      marginTop: '8px',
                    }}
                  >
                    {RewardsStrings.PrimaryCampaignTooltip}
                  </Typography>
                </Box>
              }
              icon={
                <Box>
                  <StarIcon />
                </Box>
              }
              light
            />
          )}
          <Typography fontSize="12px" fontWeight="500" color="#1A1538">
            {getStringValue(user.campaign.name)}{' '}
          </Typography>
        </Box>
      ),
      status: <StatusIndicator status={user.userStatus} />,
      clips: (
        <Typography fontSize="15px" textAlign="center" fontWeight={500} color="#1A1538">
          {user.videos ? user.videos : '---'}
        </Typography>
      ),
      result:
        user.userStatus !== 'INVITED' ? (
          user.fulfilled ? (
            <Typography fontSize="13px" color="#1A1538" textAlign="center">
              {user.campaign.isNullReward ? UsersTable.Acknowledged : UsersTable.Rewarded}
            </Typography>
          ) : (
            <Box display="flex" justifyContent="center">
              {user.campaign.endedAt ? (
                '---'
              ) : (
                <LoadingButton
                  loading={isRewardLoading}
                  onClick={() => onRewardClick(user.campaignId)}
                  variant="contained"
                  sx={{
                    padding: '2px 30px',
                    fontSize: '12px',
                    fontWeight: 600,
                    borderRadius: '100px',
                    boxShadow: 'none',
                    alignItems: 'center',
                  }}
                >
                  {user.campaign.isNullReward ? UsersTable.Acknowledge : UsersTable.Reward}
                </LoadingButton>
              )}
            </Box>
          )
        ) : (
          <></>
        ),
    };
  });

  return tableContent;
};

import { Box, Grid } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CommonTable } from '../../../common/components/table/CommonTable';

import { inviteesTableContent } from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getActiveInvitations,
  getCampaignInvitations,
  resendInvitation,
  updateCampaignActiveInvitationsSearch,
} from '../../../store/slices/campaignsSlice';

import { IEventTracker } from '../../../hooks/useTrackEvent';
import { useStyles } from './InviteesTable.style';
import formatDate from 'date-fns/format';
import parseIsoDate from 'date-fns/parseISO';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import TableFilterSelect, { TabItem } from './TableFilterSelect/TableFilterSelect';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { useToasts } from 'react-toast-notifications';
import { capitalizeWord } from '../../../services/utilities';
import {
  CommonStrings,
  InvitesLayoutStrings,
  NoFilterResultsStrings,
} from '../../../common/localization/en';
import sortBy from 'lodash/sortBy';
import { EmptyResultsPreview } from '../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { SmsMessageStatus } from '../../../api/models/smsMessages';
import { AnalyticsTab } from './AnalyticsTab/AnalyticsTab';
import { InvitationStatus } from '../../../api/models/campaigns';

export enum InviteeTableTab {
  DELIVERED,
  UNDELIVERED,
  TOTAL,
}

export interface Invitee {
  id: string;
  selectable?: boolean;
  selectableTooltip?: string;
  customerName: string;
  status: string;
  daysInStatus: string;
  source: string;
  lastInviteSent: string;
  numberOfSends: string;
  lastDeliveryStatus: string;
  cellNumber: string;
  createdAt?: string;
  lastCheckInAt?: string;
  smsOptedIn?: boolean;
  transactionDate?: string;
  dateDelivered?: string;
  firstAttemptAt?: string;
}

interface InviteesTableProps {
  handleTrackEvent: IEventTracker;
}

export const InviteesTable: FC<InviteesTableProps> = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();

  const { isLoading, search } = useTypedSelector(
    (state) => state.campaigns.campaignActiveInvitations,
  );

  const { invitations, currentCampaign } = useTypedSelector((state) => state.campaigns);

  const { id: campaignId } = useParams<{ id: string }>();

  const fetchInvitations = () => {
    if (!campaignId) return;
    dispatch(getActiveInvitations({ campaignId }));
  };

  useEffect(() => {
    fetchInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  useEffect(() => {
    return () => {
      dispatch(updateCampaignActiveInvitationsSearch(''));
    };
  }, [dispatch]);

  const inviteStatusMap: Record<InvitationStatus, string> = {
    [InvitationStatus.Invited]: InvitesLayoutStrings.Invited,
    [InvitationStatus.Visitor]: InvitesLayoutStrings.Visited,
    [InvitationStatus.Creator]: InvitesLayoutStrings.Creator,
  };

  const items = useMemo(() => {
    const result: Invitee[] = [];

    invitations.forEach((invitation) => {
      const daysSinceLastAttempt = invitation.lastAttemptAt
        ? differenceInDays(new Date(), parseIsoDate(invitation.lastAttemptAt))
        : 0;
      const minutesSinceLastAttempt = invitation.lastAttemptAt
        ? differenceInMinutes(new Date(), parseIsoDate(invitation.lastAttemptAt))
        : 0;

      let transactionDate = '';
      try {
        transactionDate = invitation.transactionDate
          ? formatDate(parseIsoDate(invitation.transactionDate), 'PP')
          : '---';
      } catch {
        transactionDate = invitation.transactionDate ? invitation.transactionDate : '---';
      }

      const firstAttemptAt = invitation.firstAttemptAt
        ? formatDate(parseIsoDate(invitation.firstAttemptAt), 'PP')
        : '---';

      result.push({
        id: invitation.id,
        selectable: invitation.attempts < 3 && minutesSinceLastAttempt > 10,
        selectableTooltip:
          minutesSinceLastAttempt < 15 ? InvitesLayoutStrings.ResendTooltip : undefined,
        customerName: `${invitation.firstName} ${invitation.lastName}`,
        status:
          invitation.lastDeliveryStatus === SmsMessageStatus.Delivered
            ? inviteStatusMap[invitation.invitationStatus]
            : InvitesLayoutStrings.Undelivered,
        daysInStatus: invitation.lastAttemptAt ? daysSinceLastAttempt.toString() : '---',
        source: 'SMS',
        lastInviteSent: invitation.lastAttemptAt
          ? formatDate(parseIsoDate(invitation.lastAttemptAt), 'PP')
          : '---',
        numberOfSends: invitation.attempts?.toString() || '---',
        lastDeliveryStatus: capitalizeWord(invitation.lastDeliveryStatus || 'Sent'),
        cellNumber: invitation.phoneNumber,
        createdAt: invitation.createdAt
          ? formatDate(parseIsoDate(invitation.createdAt), 'PP')
          : '---',
        smsOptedIn: true,
        transactionDate,
        firstAttemptAt,
        dateDelivered:
          invitation.lastMessageSentAt &&
          invitation.lastDeliveryStatus === SmsMessageStatus.Delivered
            ? formatDate(parseIsoDate(invitation.lastMessageSentAt), 'PP')
            : '---',
      });
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitations]);

  const filteredItems = useMemo(() => {
    return {
      [InviteeTableTab.DELIVERED]: items.filter(
        (item) => item.lastDeliveryStatus.toLocaleUpperCase() === SmsMessageStatus.Delivered,
      ),
      [InviteeTableTab.UNDELIVERED]: items.filter(
        (item) => item.lastDeliveryStatus.toLocaleUpperCase() !== SmsMessageStatus.Delivered,
      ),
      [InviteeTableTab.TOTAL]: items,
    };
  }, [items]);

  const tabsList: TabItem[] = useMemo(
    () => [
      {
        tabIndex: InviteeTableTab.DELIVERED,
        tabName: InvitesLayoutStrings.Delivered,
        usersCount: filteredItems[InviteeTableTab.DELIVERED].length
          ? filteredItems[InviteeTableTab.DELIVERED].length.toString()
          : '---',
        filter: undefined,
        disabled: filteredItems[InviteeTableTab.DELIVERED].length === 0,
      },
      {
        tabIndex: InviteeTableTab.UNDELIVERED,
        tabName: InvitesLayoutStrings.Undelivered,
        usersCount: filteredItems[InviteeTableTab.UNDELIVERED].length
          ? filteredItems[InviteeTableTab.UNDELIVERED].length.toString()
          : '---',
        filter: undefined,
        disabled: filteredItems[InviteeTableTab.UNDELIVERED].length === 0,
      },
    ],
    [filteredItems],
  );

  const [activeTab, setActiveTab] = useState(() => {
    const tabItem = tabsList.find((item) => {
      return item.usersCount !== '---';
    });

    if (!tabItem) return InviteeTableTab.DELIVERED;

    return tabItem.tabIndex;
  });

  const [sortingField, setSortingField] = useState<string>('');
  const [sortingDirection, setSortingDirection] = useState<'asc' | 'desc'>('asc');
  const toggleSortingDirection = () => {
    setSortingDirection((sortingDir) => {
      return sortingDir === 'asc' ? 'desc' : 'asc';
    });
  };

  const sortedAndFilteredItems = useMemo(() => {
    const result = sortBy(filteredItems[activeTab], [sortingField]).filter(
      (item) =>
        item.customerName.toLowerCase().includes(search.toLowerCase()) ||
        item.cellNumber.includes(search),
    );
    const isAscending = sortingDirection === 'asc';
    return isAscending ? result : result.reverse();
  }, [activeTab, filteredItems, sortingDirection, sortingField, search]);

  const userDataTableContents = inviteesTableContent({
    items: sortedAndFilteredItems,
    isHealthCare,
    handleResend: async (inviteId) => {
      await dispatch(resendInvitation({ campaignId, invitationId: inviteId }));
      addToast(InvitesLayoutStrings.InviteResend, { appearance: 'success' });
      dispatch(getCampaignInvitations({ campaignId }));
    },
    isUndeliveredInvites: activeTab === InviteeTableTab.UNDELIVERED,
    isActiveCampaign: !Boolean(currentCampaign?.endedAt),
  });

  const getPredictiveSearchItems = async (search: string) => {
    const predictiveSearchItems = filteredItems[activeTab]
      .filter(
        (item) =>
          item.customerName.toLowerCase().includes(search.toLowerCase()) ||
          item.cellNumber.includes(search),
      )
      .map((item) => item.customerName || '');
    return [...new Set(predictiveSearchItems)];
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.tableSelectWrapper}>
        <TableFilterSelect
          tabsList={tabsList}
          activeTabIndex={activeTab}
          setActiveTabIndex={(tabIndex) => {
            setActiveTab(tabIndex);
          }}
          type="invited"
          totalCount={
            filteredItems[InviteeTableTab.TOTAL].length
              ? filteredItems[InviteeTableTab.TOTAL].length.toString()
              : '---'
          }
        />
      </Box>
      <Box className={styles.tableWrapper}>
        {activeTab === InviteeTableTab.DELIVERED && <AnalyticsTab />}
        <Box className={styles.topWrapper}>
          <SearchBar
            placeholderText={CommonStrings.SearchByName}
            borderColor="#F2F4F7"
            adaptiveWidth
            entity="campaigns.campaignActiveInvitations"
            updateSearch={updateCampaignActiveInvitationsSearch}
            getPredictiveSearchItems={getPredictiveSearchItems}
          />
        </Box>
        <Grid className={styles.table}>
          <CommonTable
            content={userDataTableContents}
            page={1}
            totalItems={1}
            totalPages={1}
            isLoading={isLoading}
            noContent={false}
            tablePadding="0"
            size={1}
            onSizeChange={() => null}
            withSearchBar={false}
            roundedNavButtons={false}
            disableTopNav={true}
            rowClassName={styles.row}
            sort={[`${sortingField},${sortingDirection}`]}
            onSortHeaderClick={(name) => {
              if (name === sortingField) {
                toggleSortingDirection();
                return;
              }
              setSortingField(name);
              setSortingDirection('asc');
            }}
            emptyTableCustomPreview={
              <EmptyResultsPreview
                title={NoFilterResultsStrings.NoFilterResultsInviteesTitle}
                subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
              />
            }
            showEmptyTableCustomPreview={(search || '').length > 0 && items.length === 0}
          />
        </Grid>
      </Box>
    </Box>
  );
};

import { Box, Button, Modal, Typography } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { MESSAGE_MAX_CHARACTERS } from '../../../pages/integrations/SmsInvite/SmsInvite.helper';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { resetNewCampaign, setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { theme } from '../../../theme';
import {
  CommonStrings,
  CustomizeInvitationsModalStrings,
  ErrorsStrings,
} from '../../localization/en';
import { InvitationSMSImageUploader } from '../ImageUploader/uploaders/InvitationSMSImageUploader';
import { MessageBuilder } from '../MessageBuilder/MessageBuilder';
import {
  LocalMessageVariables,
  replaceMessageBodyWithApiVariables,
  replaceMessageBodyWithLocalVariables,
} from '../SMS/SMS.helper';
import { useModalsStyles } from './CusomizeInvitationsModal.style';
import { getCampaignById, patchIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import { InvitationSMSConfig, PatchIncentiveCampaignApiModel } from '../../../api/models/campaigns';
import { useClientType } from '../../../services/hooks/useClientType';
import { Toggle } from '../toggle/CustomToggle';

type CustomizeInvitationsModalProps = {
  isOpenModal: boolean;
  onClose: () => void;
  dayNumberSelected: number;
};

export const CustomizeInvitationsModal: FC<CustomizeInvitationsModalProps> = ({
  isOpenModal,
  onClose,
  dayNumberSelected,
}) => {
  const styles = useModalsStyles();
  const { currentCampaign, isLoading } = useTypedSelector((state) => state.campaigns);
  const { inviteMediaUrl } = useTypedSelector((state) => state.createCampaign);
  const { isHealthCare } = useClientType();

  const dispatch = useAppDispatch();

  const [smsInviteMessage, setSmsInviteMessage] = useState('');
  const [messageEnabled, setMessageEnabled] = useState(true);

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: currentCampaign?.rewardTitle || '',
  });

  const inviteMessagePreview = replaceMessageBodyWithLocalVariables(
    smsInviteMessage || '',
    isHealthCare,
    true,
  );

  const isDefaultMessage = dayNumberSelected === 1;

  const followUpMessage = useMemo(() => {
    return currentCampaign?.followUpMessages?.find(
      (message) => message.delayInDays === dayNumberSelected,
    );
  }, [currentCampaign, dayNumberSelected]);

  useEffect(() => {
    dispatch(setInviteMediaUrl(currentCampaign?.invitationMsgConfig?.mediaUrl || ''));
    setSmsInviteMessage(
      replaceMessageBodyWithLocalVariables(
        (isDefaultMessage ? currentCampaign?.invitationMsgConfig?.body : followUpMessage?.body) ||
          '',
        isHealthCare,
        true,
      ),
    );
    setMessageEnabled(followUpMessage?.enabled!);
  }, [
    dispatch,
    currentCampaign,
    isDefaultMessage,
    dayNumberSelected,
    followUpMessage,
    isHealthCare,
  ]);

  const handleUpdate = async () => {
    if (currentCampaign) {
      const invitationMsgConfig: InvitationSMSConfig = {
        mediaUrl: inviteMediaUrl,
        body: replaceMessageBodyWithApiVariables(smsInviteMessage, true),
      };

      const patchData: PatchIncentiveCampaignApiModel = isDefaultMessage
        ? {
            invitationMsgConfig,
          }
        : {
            followUpMessages: [...currentCampaign.followUpMessages].map((message) => {
              return message.delayInDays === dayNumberSelected
                ? {
                    ...message,
                    body: replaceMessageBodyWithApiVariables(smsInviteMessage, true),
                    enabled: messageEnabled,
                  }
                : message;
            }),
          };

      await dispatch(patchIncentiveCampaign({ campaignId: currentCampaign.id, values: patchData }));
      dispatch(getCampaignById(currentCampaign.id));
      dispatch(resetNewCampaign());
    }
  };

  const handleConfirmClick = async () => {
    await handleUpdate();
    onClose();
  };

  const isMessageChanged =
    replaceMessageBodyWithLocalVariables(
      (isDefaultMessage ? currentCampaign?.invitationMsgConfig?.body : followUpMessage?.body) || '',
      isHealthCare,
      true,
    ) !== smsInviteMessage && smsInviteMessage !== '';

  const isMediaChanged = currentCampaign?.invitationMsgConfig?.mediaUrl !== inviteMediaUrl;
  const isCheckBoxChanged = !isDefaultMessage && followUpMessage?.enabled !== messageEnabled;
  const isContentChanged = isMessageChanged || isMediaChanged || isCheckBoxChanged;

  const handleClose = () => {
    setSmsInviteMessage(currentCampaign?.invitationMsgConfig?.body!);
    dispatch(setInviteMediaUrl(currentCampaign?.invitationMsgConfig?.mediaUrl || ''));
    onClose();
  };

  return (
    <Modal
      open={isOpenModal}
      className={styles.modal}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box
        className={styles.modalContent}
        sx={{
          height: isDefaultMessage ? '560px' : '430px',
        }}
      >
        <Typography className={styles.title}>
          {CustomizeInvitationsModalStrings.ModalTitle.replace('Day', `Day ${dayNumberSelected} `)}
        </Typography>
        {!isDefaultMessage && (
          <Box className={styles.checkBoxWrapper}>
            <Toggle
              onChange={(value) => {
                setMessageEnabled(value);
              }}
              value={messageEnabled}
            />
            <Typography className={styles.sendMessageText}>
              {CustomizeInvitationsModalStrings.SendMessage}
            </Typography>
          </Box>
        )}
        <Box>
          {isDefaultMessage && (
            <InvitationSMSImageUploader
              title={'Select an Image'}
              subtitle={'Adding a familiar picture boosts video creation.'}
              imagePreviewWidth="202px"
              imagePreviewHeight="162px"
              alignment="flex-start"
              imagePadding={5}
              editInvitationModal
            />
          )}

          <Box className={styles.messageWrapper}>
            <MessageBuilder
              buttons={Object.keys(templateVariables).map((key) => ({
                title: key,
                value: key,
              }))}
              message={inviteMessagePreview}
              disabled={false}
              onMessageChange={async (message: string) => {
                setSmsInviteMessage(message);
              }}
              borderRadius={'5px'}
              background={`${theme.palette.primary.main}0f`}
              squareBrackets
              errorText={!smsInviteMessage?.trim() ? ErrorsStrings.FieldIsRequired : ''}
              limit={MESSAGE_MAX_CHARACTERS}
              withUploadImageButton={false}
              isMms={!!inviteMediaUrl}
              maxHeight="150px"
            />
          </Box>
        </Box>
        <Box className={styles.buttons}>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            {CommonStrings.Cancel}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleConfirmClick}
            disabled={isLoading || !isContentChanged}
          >
            {CustomizeInvitationsModalStrings.ConfirmChangesButton}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
